import React from 'react';
import About1 from "./section/About1";
import About2 from "./section/About2";
import About3 from "./section/About3";

const About = () => {
    return (
        <>
            <div className="overflow-hidden">
                <About1/>
                <About2/>
                <About3/>
            </div>
        </>

    )
}

export default About;

