import React from 'react';
import IntroHistory1 from "./section/History1";
import IntroHistory2 from "./section/History2";

const History = () => {
    return (
        <>
            <div className="overflow-x-hidden">
                <IntroHistory1 />
                <IntroHistory2/>
            </div>
        </>

    )
}

export default History;

