import React, { useState } from 'react';
import VideoModal from "./VideoModal";
import PlayButton from "./PlayButton";
import mainre from '../../../images/home/web/mainre2.webp';

const Video = () => {
    const [isOpen, setOpen] = useState(false);
    const [isHovered, setHovered] = useState(false);

    function handleChange() {
        setOpen(!isOpen);
    }

    function handleMouseEnter() {
        setHovered(true);
    }

    function handleMouseLeave() {
        setHovered(false);
    }

    return (
        <>
            <div className="bg-[#FAFAFA] overflow-hidden text-center
                            2xl:min-h-[40vw]  2xl:max-h-[1240px]
                            1xl:min-h-[40vw]  1xl:max-h-[1240px]
                            xl:min-h-[36vw]   xl:max-h-[1240px]
                            lg:min-h-[960px] lg:max-h-[1050px]
                            md:min-h-[820px]  md:max-h-[960px]
                            sm:min-h-[90vw]   sm:max-h-[900px] sm:min-w-[320px]">
                <style>
                    {`
                       .box {
                          background-size: cover;
                          background-position: 50% 50%;
                        }
                       .box_3 {
                          padding-top: 56.25%;
                        }
                       @media (max-width: 1439.51px) {
                          .trans-form1 {
                             padding-top:57vw;
                             padding-bottom:50px;
                             padding-left:0px !important;
                          }
                       }
                       @media (max-width: 1023px) {
                          .trans-form2 {
                             padding-top:62vw;
                             padding-left:110px;
                             padding-bottom:50px;
                          }
                       }
                       @media (max-width: 767px) {
                          .trans-form3 {
                             padding-top:65vw;
                             padding-left:40px;
                             padding-bottom:40px;
                          }
                       }
                       
                       @media (max-width: 1439.51px) {
                          .1440align {
                             text-align:center;
                          }
                       }
                       @media (max-width: 1023px) {
                          .1024align {
                            text-align:center;
                          }
                       }
                       @media (max-width: 767px) {
                          .768align {
                             text-align:center;
                          }
                       }
                       
                    `}
                </style>
                <div className="text-center ">
                    <div className="m-auto absolute
                                    1xl:ml-[120px] 1xl:mt-[89px] 1xl:min-w-[42vw] 1xl:max-w-[42vw] 1xl:w-[50%]
                                    xl:ml-[120px] xl:mt-[89px] xl:min-w-[78vw] xl:max-w-[78vw] xl:w-[50%]
                                    lg:ml-[120px] lg:mt-[89px] lg:min-w-[78vw] lg:max-w-[78vw] lg:w-[50%]
                                    md:ml-[80px]  md:mt-[69px] md:min-w-[76vw] md:max-w-[76vw] md:w-[60%]
                                    sm:ml-[15px]  sm:mt-[49px] sm:min-w-[90vw] sm:max-w-[90vw] sm:w-[60%]">
                        <div className="box box_3 bg-[#F9B959] rounded-[10px] opacity-30"></div>
                    </div>
                    <div  className="m-auto absolute overflow-hidden overflow-y-hidden rounded-[10px]
                                    1xl:ml-[159px] 1xl:mt-[121px] 1xl:min-w-[42vw] 1xl:max-w-[42vw]
                                    xl:ml-[159px] xl:mt-[121px] xl:min-w-[78vw] xl:max-w-[78vw]
                                    lg:ml-[159px] lg:mt-[121px] lg:min-w-[78vw] lg:max-w-[78vw]
                                    md:ml-[115px] md:mt-[90px]  md:min-w-[76vw] md:max-w-[76vw]
                                    sm:ml-[30px]  sm:mt-[65px]  sm:min-w-[90vw] sm:min-w-[90vw] "
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleChange}
                        style={{ cursor: 'pointer' }} >
                        <img src={mainre} className={`absolute rounded-[10px] h-[100%] hover:scale-110 transition duration-400 ${isHovered ? 'scale-110' : ''}`} />
                        <div className="absolute flex justify-center w-[100%] h-full ">
                            <PlayButton click={handleChange} isHovered={isHovered} />
                        </div>
                        <div className="box box_3 rounded-[10px]"></div>
                    </div>
                </div>
                <div className=" 1xl:pl-[48vw] xl:pl-[0px] pt-[1vw] ">
                    <div className="text-[#333333] font-[700] trans-form1 trans-form2 trans-form3 1440align 1024align 768align
                                        1xl:text-[44px] 1xl:leading-[62px] 1xl:text-left  1xl:pl-[100px] 1xl:pt-[5vw]
                                        xl:text-[40px]  xl:leading-[58px]  xl:text-center xl:pl-[100px] xl:pt-[6vw]
                                        lg:text-[40px]  lg:leading-[58px]  lg:text-center
                                        md:text-[36px]  md:leading-[48px]  md:text-center
                                        sm:text-[20px]  sm:leading-[40px]  sm:text-center "> 날개로 모이는 데는 이유가 있습니다.<br/>
                            <div className="text-[#333333] font-[700] 1440align 1024align 768align
                                            1xl:text-[32px] 1xl:leading-[62px] 1xl:pt-[15px] 1xl:text-left
                                            xl:text-[28px] xl:leading-[58px] xl:pt-[15px] xl:text-center
                                            lg:text-[28px] lg:leading-[58px] lg:pt-[15px] lg:text-center
                                            md:text-[24px] md:leading-[48px] md:pt-[15px] md:text-center
                                            sm:text-[16px] sm:leading-[40px] sm:pt-[15px] sm:text-center"> 영업보안 안전지대<br/>
                            <div className="text-[#333333] font-[500] break-keep 1440align 1024align 768align
                                                1xl:text-[28px] 1xl:leading-[56px] 1xl:text-left
                                                xl:text-[24px]  xl:leading-[52px]  xl:text-center
                                                lg:text-[22px]  lg:leading-[44px]  lg:text-center
                                                md:text-[16px]  md:leading-[40px]  md:text-center
                                                sm:text-[14px]  sm:leading-[36px]  sm:text-center">
                                                날개는 출판을 하지 않습니다.<br/>
                                                날개는 판매 유통을 하지 않습니다.<br/>
                                                날개는 순수 출판물류 전문 회사입니다.<br/>
                                                날개는 안전합니다.<br/>
                                </div>
                            </div>
                    </div>
                </div>
            </div>

            <VideoModal isOpen={isOpen} close={handleChange} />
        </>
    );
}

export default Video;
