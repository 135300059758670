import React, {useState, useEffect} from 'react';
import {FaAngleRight} from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import {gsap} from "gsap";
import {HashLink} from "react-router-hash-link";

const FAQ = () => {
    const [questionsAnswers, setQuestionsAnswers] = useState([]);
    const [category, setCategory] = useState("All");
    const [expandedQuestion, setExpandedQuestion] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredQuestions, setFilteredQuestions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://raw.githubusercontent.com/bookspider/nghp_content/main/content/faq.json');
                const data = await response.json();
                setQuestionsAnswers(data);
                setFilteredQuestions(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const handleCategoryChange = (category) => {
        setCategory(category);
        filterQuestions(category, searchTerm);
    };

    const toggleAnswer = (i) => {
        setExpandedQuestion(expandedQuestion === i ? null : i);
    };

    const filterQuestions = (category, searchTerm) => {
        const filtered = questionsAnswers.filter((item) => {
            if (category === "All" || item.category === category) {
                return item.question.toLowerCase().includes(searchTerm.toLowerCase());
            }
            return false;
        });
        setFilteredQuestions(filtered);
    };

    const handleSearch = () => {
        filterQuestions(category, searchTerm);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="max-6xl md:p-16 h-1/2 overflow-x-hidden text-center">
            <div className="flex justify-center text-center mb-[20px]">
                <div className="flex flex-col mt-20 justify-center text-center w-11/12">
                    <h1 className="lg:text-5xl font-bold mb-20 mt-20 text-center
                                   md:text-4xl
                                   sm:text-3xl sm:ml-5 sm:w-[300px] sm:mb-10
                    ">자주 묻는 질문</h1>

                    <div className="space-x-4 mb-4 relative">
                        <input type="text" value={searchTerm} onKeyUp={handleKeyPress} placeholder={"검색"}
                               onChange={(e) => setSearchTerm(e.target.value)}
                               className="w-full px-4 py-2 pl-10 rounded-3xl border border-[#16196E] border-gray-300 focus:outline-none focus:border-bg-[#26272D] min-w-[10rem] max-h-[2.8rem]" />
                        <FaSearch className="absolute right-[1rem] top-1/3 text-black cursor-pointer searchIcon"
                                  onClick={handleSearch}
                                  onMouseEnter={() => {
                                      gsap.to('.searchIcon', {scale: 1.1});
                                  }}
                                  onMouseLeave={() => {
                                      gsap.to('.searchIcon', {scale: 1});
                                  }}/>
                    </div>
                    <div className="flex space-x-4 sm:space-x-1 mb-4 mt-12 sm:flex-wrap sm:mt-1">
                        <button
                            className={`px-4 py-2 rounded-2xl min-w-[3.9rem] sm:min-w-[2rem] max-h-[2.8rem] sm:text-[14px] ${category === 'All' ? 'bg-[#16196E] text-white' : 'bg-white border border-[#16196E]'}`}
                            onClick={() => handleCategoryChange("All")}>전체
                        </button>
                        <button
                            className={`px-4 py-2 rounded-2xl min-w-[3.9rem] sm:min-w-[4.5rem] max-h-[2.8rem] sm:text-[14px] ${category === 'warehouse' ? 'bg-[#16196E]  text-white' : 'bg-white border border-[#16196E]'}`}
                            onClick={() => handleCategoryChange("warehouse")}>창고 위탁
                        </button>
                        <button
                            className={`px-4 py-2 rounded-2xl min-w-[3.9rem] sm:min-w-[2rem] max-h-[2.8rem] sm:text-[14px] ${category === 'delivery' ? 'bg-[#16196E]  text-white' : 'bg-white border border-[#16196E]'}`}
                            onClick={() => handleCategoryChange("delivery")}>배송 위탁
                        </button>
                    </div>
                    <div className="mb-4 relative border-">
                        <li className="mt-5 bottom-5 w-full h-1 bg-gray-300 list-none"></li>
                    </div>
                    {filteredQuestions.map((item, i) => {
                        if ((category === "All" || item.category === category) && item.question) {
                            return (
                                <div className="bg-white hover:bg-gray-200 rounded /*shadow-md*/ p-4 mb-4
                                                max-h-[10rem] border-b-2" key={i}>
                                    <div
                                        className="flex justify-between items-center text-xl text-gray-700 font-semibold cursor-pointer sm:text-[1rem]"
                                        onClick={() => toggleAnswer(i)}>
                                        <h2>
                                            {item.question}
                                        </h2>
                                        <span style={{
                                            transition: "transform 0.3s ease",
                                            transform: expandedQuestion === i ? "rotate(90deg)" : "none"
                                        }}>  {expandedQuestion === i ? (
                                            <FaAngleRight className="icon-arrow"/>) : (
                                            <FaAngleRight className="icon-arrow"/>
                                        )}</span>
                                    </div>
                                    {expandedQuestion === i && (
                                        <p className={"mt-2 "} data-aos="fade-in">{item.answer}</p>
                                    )}
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
            <div className="flex justify-center text-center mb-[20px]">
                <div className={"flex flex-col items-center mt-20 bg-[#FFC165] rounded-2xl 2xl:w-[56rem] xl:w-[48rem] lg:w-[42rem] md:w-[34rem] sm:min-w-[300px]"}>
                    <h2 className={"font-bold mt-5 sm:text-[15px]"}>궁금하신 점이 있다면</h2>
                    <h2 className={"font-bold sm:text-[15px]"}>언제든지 물어보세요.</h2>
                    <button className={'px-7 py-2 mt-5 mb-5 rounded-xl min-w-[5rem] max-h-[2.8rem] bg-[#45488C] text-white'}>
                        <HashLink to="/qna">문의하기</HashLink>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
