import React from 'react';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import logo from '../images/logo/nalgae2.svg';
import instagram from '../images/logo/instagram.svg';
import youtube from '../images/logo/youtube.svg';
//import facebook from '../images/logo/facebook.svg';
import headphones from '../images/logo/headphones.svg';


const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full bg-[#26272D] ">
                    <div className="grid gap-5
                                    lg:mx-[120px] lg:pt-[10vh] lg:mb-[100px] lg:grid-cols-10
                                    md:mx-[80px]  md:pt-[8vh]  md:mb-[75px]  md:grid-cols-7
                                    sm:mx-[40px]  sm:pt-[5vh]  sm:mb-[50px]
                                    ">
                        <div className="lg:col-span-6
                                        md:col-span-2
                                        sm:col-span-6
                                        ">
                            <img src={logo} alt="NALGAE" className="lg:h-[6vh]
                                                                    md:h-[5vh]
                                                                    sm:h-[4vh]
                                                                    "/>
                            <div className='text-gray-600'>
                                <div className="pt-[3vh]">
                                    <div className='p-[1vh] w-fit bg-gray-700 float-left
                                                    lg:rounded-2xl lg:mr-[1vw]
                                                    md:rounded-xl  md:mr-[1vw]
                                                    sm:rounded-lg  sm:mr-[2vw]
                                                    '>
                                        <a href="https://www.instagram.com/nalgae_logistics/" target="_blank"
                                           rel="noopener noreferrer">
                                            <img src={instagram} alt="instagram" className="rounded-xl
                                                                                        lg:h-9 md:h-8
                                                                                        sm:h-7"/>
                                        </a>
                                    </div>
                                    <div className='p-[1vh] w-fit bg-gray-700 float-left
                                                    lg:rounded-2xl lg:mr-[1vw]
                                                    md:rounded-xl  md:mr-[1vw]
                                                    sm:rounded-lg  sm:mr-[2vw]
                                                    '>
                                        <a href="https://www.youtube.com/channel/UCbGQsBDaLDKnd-uTG_SMblQ"
                                           target="_blank"
                                           rel="noopener noreferrer">
                                            <img src={youtube} alt="instagram" className="rounded-xl
                                                                                    lg:h-9 md:h-8
                                                                                    sm:h-7"/>
                                        </a>
                                    </div>
                                    {/*
                                    <div className='p-[1vh] w-fit bg-gray-700 float-left
                                                    lg:rounded-2xl lg:mr-[1vw]
                                                    md:rounded-xl  md:mr-[1vw]
                                                    sm:rounded-lg  sm:mr-[2vw]
                                                    '><img src={facebook} alt="facebook" className="rounded-xl
                                                                                                    lg:h-9
                                                                                                    md:h-8
                                                                                                    sm:h-7"/>
                                    </div>*/}
                                </div>
                            </div>
                        </div>

                        {/*footer 가 두종류 뿐이라 임시 적용중*/}
                        <div className="lg:col-span-1
                                        md:col-span-1
                                        sm:-mt-[3vh] md:hidden sm:hidden">
                        </div>
                        <div className="lg:col-span-1
                                        md:col-span-1
                                        sm:-mt-[3vh] sm:hidden">
                        </div>
                        <div className='md:hidden sm:hidden'></div>
                        <div className="lg:col-span-1
                                        md:col-span-1
                                        sm:float-left">
                            <h6 className="text-[#fff]  mb-[2vh] font-[500]
                                            lg:text-[16px] lg:leading-[19px]
                                            md:text-lg
                                            sm:text-md ">회사소개</h6>
                            <ul className="lg:text-md
                                           md:text-md
                                           sm:text-sm">
                                <li className="mb-[1vh]">
                                    <Link to="/intro"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              ">회사 개요</Link>
                                </li>
                                <li className="mb-[1vh]">
                                    <Link to="/vision"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              ">경영 이념</Link>
                                </li>
                                <li className="mb-[1vh]">
                                    <Link to="/history"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              ">연혁 소개</Link>
                                </li>
                            </ul>
                        </div>
                        {/*


                        <div className="lg:col-span-1
                                        md:col-span-1
                                        sm:float-left">
                            <h6 className="text-[#fff]  mb-[2vh] font-[500]
                                            lg:text-[16px] lg:leading-[19px]
                                            md:text-lg md:font-bold
                                            sm:text-md sm:font-medium">사업분야</h6>
                            <ul className="lg:text-md
                                           md:text-md
                                           sm:text-sm">
                                <li className="mb-[1vh] w-[100px]">
                                    <Link to="#"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              sm:hidden">물류 네트워크</Link>
                                </li>
                               <li className="mb-[1vh] w-[100px]">
                                    <Link to="#"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              sm:hidden">출판유통플랫폼</Link>
                                </li>
                            </ul>
                        </div>
                          */}
                        <div className="lg:col-span-1
                                        md:col-span-1
                                        ">
                            <h6 className="text-[#fff]  mb-[2vh] font-[500]
                                            lg:text-[16px] lg:leading-[19px]
                                            md:text-lg md:font-bold
                                            sm:text-md sm:font-medium">미디어</h6>
                            <ul className="lg:text-md
                                           md:text-md
                                           sm:text-sm">

                                <li className="mb-[1vh]">
                                    <Link to="/nalgaetv"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              ">날개 TV</Link>
                                </li>
                                {/*<li className="mb-[1vh] w-[100px]">
                                    <Link to="#"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              sm:hidden">날개TV</Link>
                                </li>
                                <li className="mb-[1vh] w-[100px]">
                                    <Link to="#"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              sm:hidden">베스트셀러</Link>
                                </li>*/}
                            </ul>
                        </div>


                        <div className="lg:col-span-1
                                        md:col-span-1
                                        sm:col-span-4
                                        ">
                            <h6 className="text-[#fff] mb-[2vh] font-[500]
                                            lg:text-[16px] lg:leading-[19px]
                                            md:text-lg md:font-bold
                                            sm:text-md sm:font-medium">고객지원</h6>
                            <ul className="lg:text-md
                                           md:text-md
                                           sm:text-sm">
                                <li className="mb-[1vh]">
                                    <Link to="/qna"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              ">고객문의</Link>
                                </li>
                                <li className="mb-[1vh]">
                                    <Link to="/faq"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              ">FAQ</Link>
                                </li>
                                <li className="mb-[1vh]">
                                    <Link to="https://113366.com/nalgae"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              ">원격지원</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="lg:col-span-1
                                        md:col-span-1 md:hidden
                                        sm:-mt-[3vh] sm:hidden">
                        </div>
                        <div className="lg:col-span-1
                                        md:col-span-1 md:hidden
                                        sm:-mt-[3vh] sm:hidden">
                        </div>
                        {/*


                        <div className="lg:col-span-1
                                        md:col-span-1
                                        sm:-mt-[3vh]">
                            <h6 className="text-[#fff]  mb-[2vh] font-[500]
                                            lg:text-[16px] lg:leading-[19px]
                                            md:text-lg md:font-bold
                                            sm:text-md sm:font-medium">채용</h6>
                            <ul className="lg:text-md
                                           md:text-md
                                           sm:text-sm">
                                <li className="mb-[1vh]">
                                    <Link to="#"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              sm:hidden">채용
                                        정보</Link>
                                </li>
                               <li className="mb-[1vh]">
                                    <Link to="#"
                                          className="text-[#D2D2D2] hover:text-gray-50 hover:tracking-wider
                                              lg:text-[14px] lg:leading-[16px]
                                              sm:hidden">공고</Link>
                                </li>
                            </ul>
                        </div>


                        */}
                    </div>
                    <div className="py-[10px] grid grid-cols-10 gap-5 lg:mx-[120px] md:mx-[80px] sm:mx-[40px] ">
                        <div className="lg:col-span-7 lg:flex
                                        md:hidden sm:hidden"></div>
                        <div className=" lg:hidden flex ">
                            <img src={headphones} alt="headphones" className="w-[20px]"/>
                            <div className="text-white lg:text-[16px] lg:leading-[19px] w-[200px] absolute pl-[30px] ">고객센터</div>
                        </div>
                        <div className="lg:flex md:hidden sm:hidden">
                            <img src={headphones} alt="headphones" className="w-[20px]  mr-[10px]"/>
                            <div className="text-white lg:text-[16px] lg:leading-[19px] w-[100px]  absolute pl-[30px] ">고객센터</div>
                        </div>
                    </div>

                    <div className="grid
                                    lg:pb-[80px] lg:mx-[120px] lg:grid-cols-10 gap-5
                                    md:pb-[60px] md:mx-[80px]
                                    sm:pb-[40px] sm:mx-[40px]
                                    ">
                        <div className="lg:col-span-6
                                        md:col-span-1
                                        sm:hidden
                                        md:hidden
                                        lg:flex">
                            <div className="text-sm text-white opacity-40 py-[1vh] ">
                                Copyright &copy; {new Date().getFullYear()}{"  "}
                                <HashLink to="#" className=" hover:text-white">
                                    Nalgae
                                </HashLink>. All rights reserved.
                            </div>
                        </div>
                        <div></div>
                        {/*lg의 경우*/}
                        <div className="w-[615px]  flex
                                        lg:text-left  lg:flex
                                        md:text-left md:hidden
                                        sm:text-left sm:hidden
                                        ">


                            <div className="text-left">
                                <div className=" text-white text-[14px] opacity-80  ">(주)날개물류</div>
                                <div className=" text-white text-[14px] opacity-40 ">031. 940. 1300</div>
                            </div>
                            <div className="text-left mx-[10px] ">
                                <div className="text-white text-[14px] opacity-80">(주)드림날개</div>
                                <div className="text-white text-[14px] opacity-40 "> 031. 945. 6001</div>
                            </div>
                            <div className="text-left mx-[10px] ">
                                <div className="text-white text-[14px] opacity-80">(주)황금날개</div>
                                <div className="text-white text-[14px] opacity-40 "> 031. 972. 4393</div>
                            </div>
                            <div className="text-left mx-[10px] ">
                                <div className="text-white text-[14px] opacity-80">(주)황금날개 플러스</div>
                                <div className="text-white text-[14px] opacity-40 "> 031. 940. 1473</div>
                            </div>
                        </div>
                        {/*md,sm의 경우*/}
                        <div className="lg:grid-cols-3
                                        lg:grid       lg:hidden
                                        md:text-left
                                        sm:text-left
                                        ">
                            <div className=" mx-auto">
                                <span className="text-white text-[14px]  opacity-80 ">(주)날개물류 </span>
                                <span className="text-white text-[14px]  opacity-40 ml-[1vw]"> 031. 940. 1300</span>
                            </div>
                            <div className=" mx-auto">
                                <span className="text-white text-[14px]  opacity-80">(주)드림날개 </span>
                                <span className="text-white text-[14px]  opacity-40 ml-[1vw]"> 031. 945. 6001</span>
                            </div>
                            <div className=" mx-auto">
                                <span className="text-white text-[14px]  opacity-80">(주)황금날개 </span>
                                <span className="text-white text-[14px]  opacity-40 ml-[1vw]"> 031. 972. 4393</span>
                            </div>
                            <div className=" mx-auto">
                                <span className="text-white text-[14px]  opacity-80">(주)황금날개 플러스</span>
                                <span className="text-white text-[14px]  opacity-40 ml-[1vw]"> 031. 940. 1473</span>
                            </div>
                        </div>


                        <div className="text-sm text-white opacity-40 pt-[40px]
                                        lg:hidden ">
                            Copyright &copy; {new Date().getFullYear()}{"  "}
                            <HashLink to="#" className=" hover:text-white">
                                Nalgae
                            </HashLink>. All rights reserved.
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;
