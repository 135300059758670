import React, {useState, useEffect, useRef} from "react";
import union1 from "../../../images/logo/Union1.png";
import axios from "axios";

import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QnA2 = () => {
    const [formData, setFormData] = useState({
        category: "배송 위탁",
        company: "",
        jopPosition:"",
        contactPerson: "",
        email: "",
        phoneNumber: "",
        bookTypes: "" , // 도서종류
        pickLocation:"", //수거위치
        expectedQuantity: "", // 출고예상부수
        bookstoreFax: "", // 서점팩스번호
        pastWay: "", // 기존운영방식 (자체,타거래처)출고예상부
        otherRequests: "" // 기타요청사항
    });
    const [isMobile, setIsMobile] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const modalBackground = useRef();

    useEffect(() => {
        if (modalOpen) {
            // 모달이 열릴 때 body에 overflow-hidden 클래스 추가
            document.body.classList.add('overflow-hidden');
        } else {
            // 모달이 닫힐 때 body에 추가된 overflow-hidden 클래스 제거
            document.body.classList.remove('overflow-hidden');
        }
    }, [modalOpen]);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // 예시로 768px 이하를 모바일로 판단하도록 설정
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const notify = () => toast("문의가 접수되었습니다");
    const yesify = () => toast("문의가 접수되었습니다");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const resetForm2 = () => {
        setFormData({
            category: "배송 위탁",
            company: "",
            jopPosition:"",
            contactPerson: "",
            email: "",
            phoneNumber: "",
            bookTypes: "" , // 도서종류
            pickLocation:"", //수거위치
            expectedQuantity: "", // 출고예상부수
            bookstoreFax: "", // 서점팩스번호
            pastWay: "", // 기존운영방식 (자체,타거래처)출고예상부
            otherRequests: "" // 기타요청사항
        });
    };

    const qnaSubmit = () => {
        if (!formData.company.trim() || !formData.phoneNumber.trim()) {
            alert("필수 입력란을 모두 입력해주세요.");
            return;
        }
        if (!document.getElementById('agreePrivacy').checked) {
            alert("개인정보 수집 및 이용에 동의하지 않으셨습니다. 동의 후에 문의를 진행해주세요.");
            return;
        }
        const qnaData = {
            "type": "message",
            "attachments": [
                {
                    "contentType": "application/vnd.microsoft.card.adaptive",
                    "contentUrl": null,
                    "content": {
                        "type": "AdaptiveCard",
                        "body": [
                            {
                                "type": "ColumnSet",
                                "columns": [
                                    {
                                        "type": "Column",
                                        "items": [
                                            {
                                                "type": "Image",
                                                "style": "Person",
                                                "url": "https://img.freepik.com/free-vector/3d-blue-notification-mark-symbol-and-social-media-communication-sign-icon-on-alert-danger-problem_40876-3476.jpg",
                                                "altText": "QnA",
                                                "size": "Medium"
                                            }
                                        ],
                                        "width": "auto"
                                    },
                                    {
                                        "type": "Column",
                                        "items": [
                                            {
                                                "type": "TextBlock",
                                                "weight": "Bolder",
                                                "text": "홈페이지 QnA",
                                                "wrap": true
                                            }
                                        ],
                                        "width": "stretch"
                                    }
                                ]
                            },
                            {
                                "type": "Table",
                                "columns": [
                                    {
                                        "width": 1.2
                                    },
                                    {
                                        "width": 2
                                    }
                                ],
                                "rows": [
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"문의분야","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [ {   "type": "TextBlock",
                                                    text: `${formData.category}`,
                                                    "isSubtle": true,
                                                    "wrap": true,
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"업체명","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [  {   "type": "TextBlock",
                                                    text: `${formData.company}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"직위","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [  {   "type": "TextBlock",
                                                    text: `${formData.jopPosition}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"담당자명","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [  {   "type": "TextBlock",
                                                    text: `${formData.contactPerson}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"연락처","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [ {   "type": "TextBlock",
                                                    text: `${formData.phoneNumber}`,
                                                    "isSubtle": true,
                                                    "wrap": true,

                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"이메일","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [ {   "type": "TextBlock",
                                                    text: `${formData.email}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"도서 종류","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [  {   "type": "TextBlock",
                                                    text: `${formData.bookTypes}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"수거 위치","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [ {   "type": "TextBlock",
                                                    text: `${formData.pickLocation}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"월평균 출고량","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [  {   "type": "TextBlock",
                                                    text: `${formData.expectedQuantity}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"팩스 번호","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [{   "type": "TextBlock",
                                                    text: `${formData.bookstoreFax}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"현재운영방식(대행사명, 자체배송, 택배발송 등)","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [{   "type": "TextBlock",
                                                    text: `${formData.pastWay}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type": "TextBlock","text": "기타 문의 / 요청 사항","weight": "Bolder","wrap": true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [{"type": "TextBlock",
                                                    text: `${formData.otherRequests}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            },
                        ],
                        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
                        "version": "1.6"
                    }
                }
            ]
        };

        // 테스트 웹훅 var webhookUrl= "https://nalgae.webhook.office.com/webhookb2/a46effe5-4040-4e51-9ebe-f5e869e60db1@9afce5e9-0f70-47c4-b2ce-7174e3b88c03/IncomingWebhook/0153d44ca84146adb47006cd397f3428/7e05e48f-59de-4c1e-a54f-d6ba09b392c8";
        var webhookUrl = "https://nalgae.webhook.office.com/webhookb2/542368f6-b20f-4d98-b528-3ec2cf0feca7@9afce5e9-0f70-47c4-b2ce-7174e3b88c03/IncomingWebhook/fb1be4ce01954fd487b8606514b27719/7e05e48f-59de-4c1e-a54f-d6ba09b392c8/V2c4Uff2B1e7p74xNfuuuWG1HzCjwqIQgIKX0hlnFSY9w1";
        alert("문의가 발송되었습니다.");
        axios.post(webhookUrl, qnaData, {
            headers: {
                'Content-Type': 'text/plain'
            }
        })

            .then(res => {
                {notify()}
                resetForm2();
            })
            .catch(error => {
                {yesify()}
                resetForm2();
            });
    };

    return (
        <>
            <img src={union1} alt="background image" className='absolute left-0 z-0 pointer-events-none
                                                                lg:scale-100 lg:ml-[0vw]   lg:mt-[20vh] lg:opacity-60
                                                                md:scale-75  md:-ml-[10vw] md:mt-[15vh] md:opacity-70
                                                                sm:scale-50  sm:-ml-[20vw] sm:mt-[15vh] sm:opacity-70'/>
            <div id='QA' className="flex flex-col w-full
                                    lg:mt-[48px]
                                    md:mt-[48px]
                                    sm:mt-[40px]">

                <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                    <div className="">
                        <label htmlFor="pastWay" className="text-[#6B7280] text-[14px] leading-[16px]">업체명</label>
                        <span className="text-red-400 text-[10px] ml-[10px]"> * 필수 입력란입니다.</span>
                        <input type="text" id="company" placeholder="업체명을 입력해 주세요." name="company"
                               value={formData.company}
                               onChange={handleChange}
                               className="lg:w-[54rem] md:w-[440px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]
                                          sm:w-[26.5rem] sm:mr-4
                               "/>
                    </div>
                </div>
                <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                    <div className="mr-[20px] lg:mb-[0px] md:mb-[24px] lg:mb-[0px] md:mb-[24px] sm:mb-[24px]">
                        <label htmlFor="jopPosition"
                               className="text-[#6B7280] text-[14px] leading-[16px] mb-[8px]">직위</label>
                        <input type="text" id="jopPosition" placeholder="담당자의 직위를 입력해 주세요." name="jopPosition"
                               value={formData.jopPosition} onChange={handleChange}
                               className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                    </div>
                    <div>
                        <label htmlFor="contactPerson" className="text-[#6B7280] text-[14px] leading-[16px]">담당자</label>
                        <input type="text" id="contactPerson" placeholder="담당자명을 입력해 주세요." name="contactPerson"
                               value={formData.contactPerson} onChange={handleChange}
                               className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                    </div>
                </div>

                <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                    <div className="mr-[20px] lg:mb-[0px] md:mb-[24px] sm:mb-[24px]">
                        <label htmlFor="email" className="text-[#6B7280] text-[14px] leading-[16px]">이메일</label>
                        <input type="text" id="email" placeholder="이메일을 입력해 주세요." name="email" value={formData.email}
                               onChange={handleChange}
                               className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                    </div>
                    <div>
                        <label htmlFor="phoneNumber" className="text-[#6B7280] text-[14px] leading-[16px]">연락처</label>
                        <span className="text-red-400 text-[10px] ml-[10px]"> * 필수 입력란입니다.</span>
                        <input type="text" id="phoneNumber" placeholder="연락처를 입력해 주세요." name="phoneNumber"
                               value={formData.phoneNumber} onChange={handleChange}
                               className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                    </div>
                </div>

                <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                    <div className="mr-[20px] lg:mb-[0px] md:mb-[24px] sm:mb-[24px]">
                        <label htmlFor="bookTypes" className="text-[#6B7280] text-[14px] leading-[16px]">도서 종류</label>
                        <input type="text" id="bookTypes" placeholder="도서 종류를 입력해 주세요." name="bookTypes"
                               value={formData.bookTypes}
                               onChange={handleChange}
                               className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                    </div>
                    <div>
                        <label htmlFor="pickLocation" className="text-[#6B7280] text-[14px] leading-[16px]">수거
                            위치</label>
                        <input type="text" id="pickLocation" placeholder="수거 위치를 입력해 주세요." name="pickLocation"
                               value={formData.pickLocation} onChange={handleChange}
                               className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                    </div>
                </div>

                <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                    <div className="mr-[20px] lg:mb-[0px] md:mb-[24px] sm:mb-[24px]">
                        <label htmlFor="expectedQuantity"
                               className="text-[#6B7280] text-[14px] leading-[16px]">월평균 출고량</label>
                        <input type="text" id="expectedQuantity" placeholder="월평균 출고량을 입력해 주세요." name="expectedQuantity"
                               value={formData.expectedQuantity}
                               onChange={handleChange}
                               className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                    </div>
                    <div>
                        <label htmlFor="bookstoreFax"
                               className="text-[#6B7280] text-[14px] leading-[16px]">팩스 번호</label>
                        <input type="text" id="bookstoreFax" placeholder="팩스 번호를 입력해 주세요." name="bookstoreFax"
                               value={formData.bookstoreFax} onChange={handleChange}
                               className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                    </div>
                </div>


                <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                    <div className="">
                        <label htmlFor="pastWay" className="text-[#6B7280] text-[14px] leading-[16px]">현재운영방식(대행사명,
                            자체배송, 택배발송 등)</label>
                        <input type="text" id="pastWay" placeholder="현재운영방식을 입력해 주세요." name="pastWay"
                               value={formData.pastWay}
                               onChange={handleChange}
                               className="lg:w-[54rem] md:w-[440px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]
                                          sm:w-[26.5rem] sm:mr-4
                               "/>
                    </div>
                </div>

                <div className={`m-auto mb-[40px] inline-flex`}>
                    <div className="">
                        <label htmlFor="otherRequests" className="text-[#6B7280] text-[14px] leading-[16px]">기타 문의 / 요청
                            사항 </label>
                        <textarea type="text" id="otherRequests" placeholder={"-"} name="otherRequests"
                                  value={formData.otherRequests}
                                  onChange={handleChange}
                                  className="lg:w-[54rem] md:w-[440px] sm:w-[345px] h-[10rem] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]
                                            sm:w-[26.5rem] sm:mr-4
                                  "/>
                    </div>
                </div>
                <div className="relative flex justify-center mr-[30rem] md:w-full sm:w-full sm:-ml-2">
                    <input type="checkbox" id="agreePrivacy" className="mr-2"/>
                    <label htmlFor="agreePrivacy" className="text-xl sm:text-[1rem] text-black ">개인정보 수집 및 이용에 동의합니다.
                        <button
                            className={'modal-open-btn text-[#096AFB] border-b-2 border-[#096AFB] cursor-pointer ml-1'}
                            onClick={() => setModalOpen(true)}>
                            내용보기
                        </button>
                    </label>
                    {modalOpen &&
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                            <div className="absolute inset-0 bg-black opacity-50 z-40"></div>
                            <div className="bg-white shadow-lg rounded-[16px] p-8 w-[600px] z-50">
                                <h2 className="text-[24px] font-[700] mb-4">개인정보 활용동의</h2>
                                <div className="bg-[#F3F4F6] p-4 rounded-md mb-2">
                                    <ul className="pl-4 font-[400] text-[14px]">
                                        <p className="mb-4 font-[500] text-[16px]">회사는 수집한 개인정보를 다음의 목적으로
                                            보유하고 활용합니다.</p>
                                        <li className={"mb-1"}><span className={"text-[10px]"}>①</span> 수집/이용 목적 : 고객문의
                                            응대 및 상담
                                        </li>
                                        <li className={"mb-1"}><span className={"text-[10px]"}>②</span> 수집/이용 항목 : 업체명,
                                            문의자, 이메일, 전화번호
                                        </li>
                                        <li className={"mb-1"}><span className={"text-[10px]"}>③</span> 보유 기간 : 문의처리
                                            완료일부터 1년
                                        </li>
                                    </ul>
                                </div>
                                <p className="text-gray-600">※ 본 동의는 당사의 고객문의를 이용하기 위해 필수로 필요한 항목입니다.</p>
                                <div className={"flex justify-center mt-7"}>
                                    <button
                                        className="border-2 border-[#16196E]  w-5/12 hover:border-purple-600 font-bold py-2 px-4 rounded"
                                        onClick={() => setModalOpen(false)}>닫기
                                    </button>
                                </div>
                            </div>

                        </div>
                    }
                </div>
                <div className={"mx-auto mt-[66px] mb-24"}>
                    <button className="text-white bg-[#16196E] hover:bg-gray-900 w-[300px] h-[51px] rounded-[4px]"
                            onClick={qnaSubmit}>문의하기
                    </button>
                </div>

            </div>

        </>
    );
}

export default QnA2;
