import React, { useEffect } from 'react';
import Statistics from "./statistics/Statistics";


const Chart = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div>
                <Statistics/>
            </div>
        </>
    )
}

export default Chart;
