import React, { useState, useEffect } from 'react';

const Notice1 = () => {


    return (
        <div>
            <div className="flex flex-col mt-20
                            2xl:mx-[32rem] 2xl:w-3/5
                            xl:mx-[18rem]  xl:w-4/5
                            lg:mx-[10rem]  lg:w-4/5
                            md:mx-[10rem] md:w-4/5
                            sm:mx-[2rem] sm:w-11/12


            ">
                <h1 className="font-bold mb-20 mt-20
                               2xl:text-5xl
                               lg:text-5xl
                               md:text-4xl
                               sm:text-4xl sm:ml-5 sm:w-[300px] sm:mb-10

                ">공지사항</h1>

            </div>

        </div>
    );
};

export default Notice1;
