/*
문서의 제목을 동적으로 변경
 */

import { useEffect, useState } from "react";

export type UseDocTitleReturnType = [string, React.Dispatch<React.SetStateAction<string>>];

const useDocTitle = (title: string): UseDocTitleReturnType => {
  const [doctitle, setDocTitle] = useState<string>(title);

  useEffect(() => {
    document.title = doctitle;
  }, [doctitle]);

  return [doctitle, setDocTitle];
};

export { useDocTitle };
