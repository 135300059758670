import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const generateMonthCategories = (series) => {
    const categories = [];
    let year = 22;
    let month = 1;
    const dataLength = series.length > 0 ? series[0].data.length : 0;
    for (let i = 0; i < dataLength; i++) {
        categories.push(`${year}.${month.toString().padStart(2, '0')}`);
        month++;
        if (month > 12) {
            month = 1;
            year++;
        }
    }
    return categories;
};

export const Statistics2 = () => {
    const [chartData, setChartData] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%'
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + "%"
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40,
                fontSize:'15px',
                fontFamily: 'Pretendard-Regular',
            },
            xaxis: {
                categories: [],
            },
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchChartData();
                setChartData(prevState => ({
                    ...prevState,
                    series: data,
                    options: {
                        ...prevState.options,
                        xaxis: { categories: generateMonthCategories(data) }
                    }
                }));
            } catch (error) {
                console.error("Error fetching chart data:", error);
            }
        };
        fetchData();
    }, []);

    const fetchChartData = () => {
        return new Promise(resolve => {
            fetch('https://raw.githubusercontent.com/bookspider/nghp_content/main/content/chart.json')
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(error => console.error("Error fetching chart data:", error));
        });
    };

    return (
        <div className="">
            <div id="chart" className="lg:px-[30px] lg:py-[30px]
                                        md:px-[20px] md:py-[30px]
                                        sm:px-[10px] sm:py-[20px] " id="chart">
                <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={650} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};
