import React from "react";
import o1 from '../../../images/home/web/o1.svg';
import w1 from '../../../images/home/web/w1.svg';
import d1 from '../../../images/home/web/d1.svg';

const Home3 = () => {

    return (
        <>
            <div className='bg-[#FAFAFA] h-[100vh] pt-[120px] overflow-x-hidden
                            lg:min-h-[1100px] lg:max-h-[900px]
                            md:min-h-[700px]  md:max-h-[800px]
                            sm:min-w-[320px]  sm:min-h-[1650px]
                            '>
                <style>
                    {`
                        @media (min-width: 768px) {
                             .hover-tilt1:hover {
                              transform: translateY(-50px) rotate(-2deg);
                            }
                             .hover-tilt2:hover {
                              transform: translateY(-50px) rotate(0deg);
                            }
                            .hover-tilt3:hover {
                              transform: translateY(-50px) rotate(2deg);
                            }
                        }
                
                    `}
                </style>
                <div data-aos="fade-up" data-aos-delay="50" className="text-center
                                                                       lg:text-[44px] lg:leading-[58px] lg:font-bold lg:text-[#333333] lg:pb-[64px]
                                                                       md:text-[32px] md:leading-[38px] md:font-bold md:text-[#333333] md:pb-[64px]
                                                                       sm:text-[18px] sm:leading-[28px] sm:font-bold md:text-[#333333] sm:pb-[64px]
                                                                       ">
                    대한민국 출판 물류 1등 기업만의<br/>도서물류 솔루션을 지금 바로 경험해 보세요!
                </div>

                <div className='text-center  sm:grid '>
                    <div className="transition duration-300 relative inline-block hover-translate-up hover-tilt1 sm:pb-[100px]  ">
                        {/*data-aos="fade-up" data-aos-delay="200" */}
                        <div className="bg-white rounded-[16px] text-left inline-block  shadow-2xl lg:relative  lg:p-[35px] md:p-[28px] sm:p-[25px]
                                                                                lg:w-[26vw] lg:h-[620px]   lg:top-[48px]
                                                                                md:w-[26vw] md:h-[460px]   md:top-[48px]
                                                                                sm:w-[70vw] sm:h-[380px]   sm:top-[40px] ">
                            <div className="mb-[16px] ">
                                <img className="lg:w-1/4 md:1/4 sm:1/8" src={o1}/>
                            </div>
                            <div className="font-bold color-[#172041] mb-[6px]
                                       lg:text-[24px] lg:leading-[28px] lg:mt-[8px]
                                       md:text-[20px] md:leading-[25px] md:mt-[8px]
                                       sm:text-[16px] sm:leading-[22px] sm:mt-[6px] ">주문 관리 시스템
                            </div>
                            <div className="text-[#8C8C8C] font-[400]
                                            lg:text-[15px] lg:leading-[19.09px]
                                            md:text-[11px] md:leading-[14px]
                                            sm:text-[10px] sm:leading-[12px]
                                            ">Order Management System
                            </div>
                            <ul className='text-[#242429] list-disc list-outside break-keep lg:h-[200px] md:h-[200px] sm:h-[200px] ml-[20px]
                                                lg:text-[15px] lg:leading-[24.64px] lg:mt-[24px]
                                                md:text-[12px] md:leading-[16px] md:mt-[20px]
                                                sm:text-[12px] sm:leading-[16px] sm:mt-[16px]'>
                                <li className=''>편리하고 신속한 자체 개발 주문 프로그램(PISS) 운영
                                    <button className=" bg-[#3E91FF] bg-opacity-[13%] text-center rounded-[4px] text-[#5472D4]
                                                           lg:text-[13px] lg:leading-[15.5px] lg:ml-[6px] lg:py-[2px] lg:px-[4px]
                                                           md:text-[12px] md:leading-[15.5px] md:ml-[6px] md:py-[2px] md:px-[4px]
                                                           sm:text-[11px] sm:leading-[13px] sm:ml-[5px] sm:py-[1px] sm:px-[3px]">고도화개발중</button>
                                </li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>입고/관리/정반품 도서의 실시간 재고 조회 가능</li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>MDI(다중 화면 인터페이스) 지원으로 다중화 복합 작업 가능 </li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>택배사 연동 시스템을 통한 택배송장 처리 결과 확인 기능 </li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>자체 전산개발팀 운영을 통한 맞춤형 프로그램 제공 가능 </li>
                            </ul>
                            <div className="lg:mt-[40px] md:mt-[20px] rounded-[8px] overflow-hidden ">
                            </div>
                        </div>
                    </div>


                    <div
                        className="mx-[1vw] transition duration-300 relative inline-block hover-translate-up hover-tilt2  sm:pb-[100px] ">
                        {/*data-aos="fade-up" data-aos-delay="200" */}
                        <div className="bg-white rounded-[16px] text-left inline-block  shadow-2xl lg:relative lg:p-[35px] md:p-[28px] sm:p-[25px]
                                                                                lg:w-[26vw] lg:h-[620px]   lg:top-[48px]
                                                                                md:w-[26vw] md:h-[460px]   md:top-[48px]
                                                                                sm:w-[70vw] sm:h-[380px]   sm:top-[40px] ">
                            <div className="mb-[16px]">
                                <img className="lg:w-1/4 md:1/4 sm:1/8"  src={w1}/>
                            </div>
                            <div className="font-bold color-[#172041] mb-[6px]
                                       lg:text-[24px] lg:leading-[28px] lg:mt-[8px]
                                       md:text-[20px] md:leading-[25px] md:mt-[8px]
                                       sm:text-[16px] sm:leading-[22px] sm:mt-[6px] ">창고 관리 시스템
                            </div>
                            <div className="text-[#8C8C8C] font-[400] w-[400px]
                                            lg:text-[15px] lg:leading-[19.09px]
                                            md:text-[11px] md:leading-[14px]
                                            sm:text-[10px] sm:leading-[12px]
                                            ">Warehouse Management System
                            </div>
                            <ul className='text-[#242429] list-disc list-outside break-keep lg:h-[200px] md:h-[200px] sm:h-[200px] ml-[20px]
                                                lg:text-[15px] lg:leading-[24.64px] lg:mt-[24px]
                                                md:text-[12px] md:leading-[16px] md:mt-[20px]
                                                sm:text-[12px] sm:leading-[16px] sm:mt-[16px]'>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>입고부터 출고까지 실시간 추적/통계 가능한 재고관리 시스템 운영 </li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>자체 개발의 원스톱 물류관리 프로그램을 통한 출고/반품 관리의 편의성 </li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>중량 계근 시스템 도입을 통한 빠르고 신뢰성 높은 검수 시스템 운영 </li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>바코드 번지 로케이션 관리 및 PDA 정보화를 통한 실시간 재고관리시스템 </li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>대규모 입지의 현대식 창고 관리 시스템을 통한 안전하고 쾌적한 도서 관리  </li>
                            </ul>
                            <div className="lg:mt-[40px] md:mt-[20px] rounded-[8px] overflow-hidden ">
                            </div>
                        </div>
                    </div>

                    <div
                        className=" transition duration-300 relative inline-block hover-translate-up hover-tilt3  ">
                        {/*data-aos="fade-up" data-aos-delay="200" */}
                        <div className="bg-white rounded-[16px] text-left inline-block  shadow-2xl lg:relative  lg:p-[35px] md:p-[28px] sm:p-[25px]
                                                                                lg:w-[26vw]  lg:h-[620px]   lg:top-[48px]
                                                                                md:w-[26vw] md:h-[460px]   md:top-[48px]
                                                                                sm:w-[70vw] sm:h-[380px]   sm:top-[40px] ">
                            <div className="mb-[16px]">
                                <img className="lg:w-1/4 md:1/4 sm:1/8"  src={d1}/>
                            </div>
                            <div className="font-bold color-[#172041] mb-[6px]
                                       lg:text-[24px] lg:leading-[28px] lg:mt-[8px]
                                       md:text-[20px] md:leading-[25px] md:mt-[8px]
                                       sm:text-[16px] sm:leading-[22px] sm:mt-[6px] ">배송 관리 시스템
                            </div>
                            <div className="text-[#8C8C8C] font-[400] w-[400px]
                                            lg:text-[15px] lg:leading-[19.09px]
                                            md:text-[11px] md:leading-[14px]
                                            sm:text-[10px] sm:leading-[12px]
                                            ">Delivery Management System
                            </div>
                            <ul className='text-[#242429] list-disc list-outside break-keep lg:h-[200px] md:h-[200px] sm:h-[200px] ml-[20px]
                                                lg:text-[15px] lg:leading-[24.64px] lg:mt-[24px]
                                                md:text-[12px] md:leading-[16px] md:mt-[20px]
                                                sm:text-[12px] sm:leading-[16px] sm:mt-[16px]'>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>배송직 전 사원 화물운수 종사자 자격증 취득의 전문성 확보 </li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>전 차량 탑차 운영을 통한 안전한 도서 배송 서비스 제공 </li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>직영체제를 갖춘 전국 직배송 시스템 구축의 안정적 운영 </li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>GIS/TMS 시스템 도입 및 PDA 정보화를 통한 실시간 배송 확인 </li>
                                <li className='lg:mt-[12px] md:mt-[10px] sm:mt-[8px]'>첨단 자동 분배 시스템인 컨베이어 소터기를 통한 신속/정확한 배송 지원 </li>
                            </ul>
                            <div className="lg:mt-[40px] md:mt-[20px] rounded-[8px] overflow-hidden ">
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Home3;
