import React from 'react';

import Notice2 from "./section/Notice2";
import Notice1 from "./section/Notice1";
import Notice3 from "./section/Notice3";
import Notice4 from "./section/Notice4";

const Notice = () => {
    return (
        <>
            <Notice1/>
            <Notice2/>
            {/*<Notice3/>*/}
        </>

    )
}

export default Notice;

