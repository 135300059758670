import React, { useState, useEffect,useRef  } from 'react';
import { HashLink } from 'react-router-hash-link';
import logo from '../../images/logo/nalgae.svg';
import logo2 from '../../images/logo/nalgae2.svg';
import top from '../../images/top2.png';
import union2 from "../../images/logo/Union2.png";
import arrow1 from "../../images/home/web/arrow.svg";

const NavBar = () => {
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [isTopButtonVisible, setIsTopButtonVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isMenuHovered, setIsMenuHovered] = useState(false);
    const [logoSrc, setLogoSrc] = useState(logo);
    const [subMenuVisible, setSubMenuVisible] = useState(false);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const navRef = useRef(null);

    const handleOverlayClick = () => {
        setIsOpen(false);
        setIsOverlayVisible(false);
    };

    const handleMobileMenuClick = () => {
        setIsOpen(!isOpen);
        setIsOverlayVisible(!isOverlayVisible);
    };

    const overlayOpacity = isOpen ? 0.5 : 0;

    const handleClickOutside = (event) => {
        if (navRef.current && !navRef.current.contains(event.target)) {
            setIsOpen(false);
            setIsOverlayVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMenuClick = () => {
        setIsMenuHovered(false);
        setIsOpen(false);
    }

    const scrollTop = () => {
        const scrollStep = -window.scrollY / (500 / 15);
        const scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            const WindowHeight = windowHeight * 0.3;

            if (scrollY > WindowHeight) {
                setIsMenuVisible(false);
                setIsTopButtonVisible(true);
            } else {
                setIsMenuVisible(true);
                setIsTopButtonVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleMouseMove = (event) => {
            const mouseY = event.clientY;
            const threshold = 300;

            if (mouseY < threshold) {
                setIsMenuVisible(true);
            } else {
                setIsMenuVisible(true);
            }
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        setLogoSrc(isMenuHovered ? logo2 : logo);
    }, [isMenuHovered]);

    return (
        <div>
            <nav className={`fixed top-0 w-[100vw] z-10 transition duration-300 ease-in-out overflow-x-hidden ${!isMenuVisible && 'slide-up '}`}
                 style={{overflow: 'hidden'}}>
                <style>
                    {`
                    .navbar {
                    }
               
                    @media (min-width: 768px) {
                        .navbar:hover {
                            background-color: #1F202C;
                            opacity: 0.9;
                            transition: background-color 0.5s ease;
                        }
                    }
                    
                   .slide-up {
                        transform: translateY(-100%);
                        transition: transform 0.3s ease;
                    }
                    `}
                </style>

                <div className="flex navbar sm:hidden z-10
                                lg:min-h-[80px]  lg:justify-between lg:bg-white lg:opacity-100
                                md:min-h-[80px]  md:justify-between md:bg-white md:opacity-100 "
                     onMouseEnter={() => setIsMenuHovered(true)}
                     onMouseLeave={() => setIsMenuHovered(false)}>
                    <div onClick={() => window.location.href = '/'} style={{cursor: 'pointer'}} >
                        <HashLink to="/" onClick={handleMenuClick}>
                            <img src={logoSrc} alt="nalgae" className="fixed
                                                                       lg:h-[40px]    lg:left-[120px] lg:top-[20px]
                                                                       md:h-[40px]    md:left-[80px] md:top-[20px]
                                                                       sm:h-[29.5px]  sm:left-[40px] sm:top-[21.5px] sm:hidden
                            "/>
                        </HashLink>
                    </div>

                    <div className="flex flex-col items-center lg:mr-[120px] md:mr-[80px] sm:mr-[40px]">
                        {/*웹메뉴*/}
                        <div className='sm:hidden lg:mr-[40px] mt-[30.5px]'>
                            <MenuItem title="회사 소개"
                                      onMouseEnter={() => setSubMenuVisible(true)}
                                      onMouseLeave={() => setSubMenuVisible(false)}
                                      isMenuHovered={isMenuHovered}>
                                <SubMenu isVisible={subMenuVisible}>
                                    <hr className={`absolute mt-[2px] left-1/2 -translate-x-1/2 border-solid w-[9999px]  border-[#ffffff]  transition-colors duration-500 hover:border-[#575757] `} />
                                    <div className='text-center mt-[20px] border-t-2 border-transparent  transition-colors duration-500 hover:border-orange-500 hover:mt-[20px]'>
                                        <HashLink to="/intro" onClick={handleMenuClick}
                                                  className={`block mt-[30px] text-[#CECECE]  hover:font-bold   hover:text-orange-500  ${isMenuHovered ? 'text-white' : 'text-gray-900'}`}>회사 개요</HashLink>
                                        <HashLink to="/vision" onClick={handleMenuClick}
                                                  className={`block mt-[24px] text-[#CECECE]  hover:font-bold  hover:text-orange-500 ${isMenuHovered ? 'text-white' : 'text-gray-900'}`}>경영 이념</HashLink>
                                        <HashLink to="/history"
                                                  className={`block mt-[24px] mb-[60px] text-[#CECECE]   hover:font-bold  hover:text-orange-500 ${isMenuHovered ? 'text-white' : 'text-gray-900'}`}>연혁 소개</HashLink>
                                    </div>
                                </SubMenu>
                            </MenuItem>

                            <MenuItem title="미디어"
                                      onMouseEnter={() => setSubMenuVisible(true)}
                                      onMouseLeave={() => setSubMenuVisible(false)}
                                      isMenuHovered={isMenuHovered}>
                                <SubMenu isVisible={subMenuVisible}>
                                    <div className='text-center mt-[20px] border-t-2 border-transparent  transition-colors duration-500 hover:border-orange-500 hover:mt-[20px]'>
                                        <HashLink to="/nalgaetv" onClick={handleMenuClick}
                                                  className={`block mt-[30px] text-[#CECECE] hover:font-black hover:font-bold  hover:text-orange-500 ${isMenuHovered ? 'text-white' : 'text-gray-900'}`}>날개 TV</HashLink>
                                        <HashLink
                                            className={`cursor-default block mt-[24px] text-[#CECECE]  hover:font-black hover:font-bold  hover:text-orange-500 ${isMenuHovered ? 'text-white' : 'text-gray-900'}`}>ㅤ</HashLink>
                                        <HashLink
                                            className={`cursor-default block mt-[24px] mb-[60px] text-[#CECECE]  hover:font-black hover:font-bold  hover:text-orange-500 ${isMenuHovered ? 'text-white' : 'text-gray-900'}`}>ㅤ</HashLink>
                                    </div>
                                </SubMenu>
                            </MenuItem>


                            <MenuItem title="고객 지원"
                                      onMouseEnter={() => setSubMenuVisible(true)}
                                      onMouseLeave={() => setSubMenuVisible(false)}
                                      isMenuHovered={isMenuHovered}>
                                <SubMenu isVisible={subMenuVisible}>
                                    <div className='text-center mt-[20px] border-t-2 border-transparent  transition-colors duration-500 hover:border-orange-500 hover:mt-[20px]'>
                                        <HashLink to="/qna" onClick={handleMenuClick}
                                                  className={`block mt-[30px] text-[#CECECE] hover:font-black hover:font-bold  hover:text-orange-500 ${isMenuHovered ? 'text-white' : 'text-gray-900'}`}>고객 문의</HashLink>
                                        <HashLink to="/faq" onClick={handleMenuClick}
                                                  className={`block mt-[24px] text-[#CECECE]  hover:font-black hover:font-bold  hover:text-orange-500 ${isMenuHovered ? 'text-white' : 'text-gray-900'}`}>FAQ</HashLink>
                                        <HashLink to="https://113366.com/nalgae"
                                                  className={`block mt-[24px] mb-[60px] text-[#CECECE]   hover:font-bold  hover:text-orange-500 ${isMenuHovered ? 'text-white' : 'text-gray-900'}`}>원격 지원</HashLink>
                                    </div>
                                </SubMenu>
                            </MenuItem>
                        </div>
                    </div>
                </div>

                {/*모바일메뉴*/}
                <div className={`flex navbar lg:hidden md:hidden sm:min-h-[80px] sm:place-content-end ${isOpen ? '' : 'bg-white'}`}
                     onMouseEnter={() => setIsMenuHovered(true)}
                     onMouseLeave={() => setIsMenuHovered(false)}>
                    <div onClick={() => window.location.href = '/'} style={{cursor: 'pointer'}}>
                        <HashLink to="/" onClick={handleMenuClick}>
                            <img src={logo} alt="NALGAE" className="fixed top-[1.5vh]
                                                                     lg:h-[40px]    lg:left-[50px]  lg:hidden
                                                                     md:h-[40px]    md:left-[40px] md:hidden
                                                                     sm:h-[29.5px]  sm:left-[34px] sm:top-[21.5px]
                            "/>
                        </HashLink>
                    </div>
                    <div className="pr-[30px] flex align-middle">
                        <button className="right-0 lg:hidden text-black z-[21]" onClick={handleMobileMenuClick}>
                            <svg className="w-[30px] flex z-[21]" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 24 24">
                                {isOpen ? (
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                                ) : (
                                    <path fillRule="evenodd"
                                          d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
                                )}
                            </svg>
                        </button>

                        {/*모바일메뉴*/}
                        <div className="z-20">
                            <div
                                className={`fixed lg:hidden md:hidden right-0 w-[300px] pb-[1000px] bg-[#fafafa]  transition-transform duration-300 top-[${isOpen ? '100%' : '0px'}] transition-transform ${
                                    isOpen ? "translate-x-0" : "translate-x-full"
                                }`}>
                                <img src={union2} alt="background image"
                                     className=' absolute left-0 z-0 pointer-events-none sm:scale-50  sm:left-[75px] sm:mt-[40vh] sm:opacity-50'/>
                                <div className="block sm:pt-[80px]">
                                    <MenuItem title="회사 소개" onMouseEnter={() => setSubMenuVisible(true)}
                                              onMouseLeave={() => setSubMenuVisible(false)}
                                              isMenuHovered={isMenuHovered}>
                                        <SubMenu isVisible={subMenuVisible}>
                                            <HashLink to="/intro" onClick={handleMenuClick}
                                                      className={`block pt-[4px] pl-[16px] text-[#242429] hover:font-bold  hover:text-[#E99E0D]`}>회사
                                                개요</HashLink>
                                            <HashLink to="/vision" onClick={handleMenuClick}
                                                      className={`block pt-[4px] pl-[16px] text-[#242429] hover:font-bold  hover:text-[#E99E0D]`}>경영
                                                이념</HashLink>
                                            <HashLink to="/history" onClick={handleMenuClick}
                                                      className={`block pt-[4px] pl-[16px] text-[#242429] hover:font-bold  hover:text-[#E99E0D]`}>연혁
                                                소개</HashLink>
                                        </SubMenu>
                                    </MenuItem>

                                    <MenuItem title="미디어" onMouseEnter={() => setSubMenuVisible(true)}
                                              onMouseLeave={() => setSubMenuVisible(false)}
                                              isMenuHovered={isMenuHovered}>
                                        {/* Pass subMenuVisible state to MenuItem */}
                                        <SubMenu isVisible={subMenuVisible}>
                                            <HashLink to="/nalgaetv" onClick={handleMenuClick}
                                                      className={`block pt-[4px] pl-[16px] text-[#242429] hover:font-bold  hover:text-[#E99E0D]`}>날개
                                                TV</HashLink>
                                        </SubMenu>
                                    </MenuItem>

                                    <MenuItem title="고객 지원" onMouseEnter={() => setSubMenuVisible(true)}
                                              onMouseLeave={() => setSubMenuVisible(false)}
                                              isMenuHovered={isMenuHovered}>
                                        {/* Pass subMenuVisible state to MenuItem */}
                                        <SubMenu isVisible={subMenuVisible}>
                                            <HashLink to="/qna" onClick={handleMenuClick}
                                                      className={`block pt-[4px] pl-[16px] text-[#242429] hover:font-bold  hover:text-[#E99E0D]`}>고객
                                                문의</HashLink>
                                            {/*
                                            <HashLink to="/notice" onClick={handleMenuClick}
                                                      className={`block pt-[4px] pl-[16px] text-[#242429] hover:font-bold  hover:text-[#E99E0D]`}>공지 사항</HashLink>
                                           */}
                                            <HashLink to="/faq" onClick={handleMenuClick}
                                                      className={`block pt-[4px] pl-[16px] text-[#242429] hover:font-bold  hover:text-[#E99E0D]`}>FAQ</HashLink>
                                            <HashLink to="https://113366.com/nalgae"
                                                      className="block pt-[1vh] pl-[16px] text-gray-400 hover:font-bold  pointer-events-none">원격지원
                                                <span className="text-[13px]">(웹 전용메뉴)</span></HashLink>
                                        </SubMenu>
                                    </MenuItem>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </nav>
            <div>

               {/* <div
                    className="fixed h-[400px] w-[100px] mt-[300px] top-0 right-0 border-gray-300 border-[1px] card-section z-10">
                    <style>
                        {`
                        .drop-filter {
                          backdrop-filter: blur(5px);
                          background-color: rgba(0, 0, 0, 0.1);
                        }
                        
                        .card1:before {
                            background-image: url("https://img.freepik.com/premium-vector/free-vector-youtube-icon-logo-social-media-logo_901408-454.jpg");
                        }
                        
                        .motion-wrap:before {
                            content: "";
                            width: 100%;
                            height: 0;
                            background-size: cover;
                            background-position: bottom;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            transform: scale(1);
                            transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
                        }
                    `}
                    </style>
                    <div
                        className="hover:card1 motion-wrap drop-filter  w-[100px] h-[100px] border-gray-300 border-b-[1px] "></div>
                    <div className="drop-filter hover:card1 w-[100px] h-[100px] border-gray-300 border-b-[1px]"></div>
                    <div className="drop-filter  w-[100px] h-[100px] border-gray-300 border-b-[1px]"></div>
                    <div className="drop-filter  w-[100px] h-[100px] border-gray-300 border-b-[1px]"></div>
                </div>*/}

                <button onClick={scrollTop} style={{
                    position: 'fixed',
                    bottom: '5px',
                    right: '5px',
                    visibility: isTopButtonVisible ? 'visible' : 'hidden',
                    zIndex: 100
                }}>
                    <img src={top} alt="NALGAE" className="h-8 md:h-10 lg:h-12 scale-150 m-10 z-10"/>
                </button>
            </div>
            {isOverlayVisible && (
                <div
                    className="fixed inset-0 bg-black transition-opacity"
                    style={{opacity: overlayOpacity, zIndex: 9}}
                    onClick={handleOverlayClick}
                />
            )}
        </div>
    )
}

const MenuItem = ({title, link, children, isMenuHovered}) => {
    const [isVisible, setIsVisible] = useState(false);
    const handleArrowClick = () => {
        setIsVisible(!isVisible);
    };

    return (
        <>
            <div className=" lg:inline-block md:inline-block relative sm:hidden "
                 onMouseEnter={() => setIsVisible(true)}
                 onMouseLeave={() => setIsVisible(true)}>
                <HashLink smooth to={link} className={`font-[500] ${isMenuHovered ? 'text-white ' : 'text-[#464646] '}
                                                             lg:text-[16px] lg:leading-[19px]  lg:px-[32px]
                                                             md:text-[16px] md:leading-[19px]  md:px-[32px]`}>
                    {title}
                </HashLink>
                {isMenuHovered && children}
            </div>
            <style>
                {`
                    .border-double-l{
                     border-left: #e98e0d61 10px double;
                    }
                    .border-none{
                    }
                `}
            </style>
            <div className="lg:hidden md:hidden ml-[48px] sm:w-[300px] ">
                <div className="grid grid-cols-2 ">
                    <div className="flex items-center" onClick={handleArrowClick}>
                        <HashLink smooth to={link}
                                  className={`sm:w-[100px] md:px-8 lg:px-12 font-bold ${isMenuHovered ? 'text-[#242429]' : 'text-[#242429]'} md:text-xl  sm:text-[20px] sm:pt-[15px]`}>
                            {title}
                        </HashLink>
                    </div>
                    <div className="">
                        <button onClick={handleArrowClick}
                                className="text-[#242429] focus:outline-none lg:hidden md:hidden pt-[20px] ">
                            <img className={`h-[45px] sm:w-[100px] inline ${isVisible ? 'rotate0' : 'rotate-90'}`}
                                 alt="card1" src={arrow1} style={{transition: 'transform 0.3s ease'}}/>
                        </button>
                    </div>
                    {isVisible && children}
                </div>
            </div>
        </>

    )
        ;
};


const SubMenu = ({children, isVisible}) => {
    return (
        <div className={`left-0 text-white transition-all duration-300 max-h-96 ${isVisible ? 'opacity-100 max-h-96' : ' text-white  opacity-100 max-h-0 '}`}>
            {children}
        </div>
    );
};


export default NavBar;
