import React, {useEffect, useState} from 'react';
import map1  from '../../../images/home/web/map.svg';
import Lottie from "react-lottie";
import oh from "../../../images/lottie/oh.json";

const Home4 = () => {
    const [isTruckAnimated, setIsTruckAnimated] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: oh,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    useEffect(() => {
        const handleScroll = () => {
            const truckElement = document.querySelector('.truck-animation');
            if (truckElement) {
                const truckPosition = truckElement.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;
                if (truckPosition < windowHeight * 0.75) {
                    setIsTruckAnimated(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {/*
            <div className="relative
                            lg:scale-100
                            md:scale-150
                            sm:scale-[200%]
                            ">
                <img alt="truck img"  style={{ position: 'absolute',
                                               transform: isTruckAnimated ? 'translate(25%, 15%) ' : 'translate(calc(-28vw), calc(-25%))',
                                               transition: 'transform 2.6s cubic-bezier(0.42, 1.25, 1, 1) 0s'
                                             }}
                     src={truck}    className="truck-animation relative flex z-20
                                                lg:pt-[8vh]
                                                md:pt-[4vh]
                                                sm:pt-[1vh]
                                                " />
            </div>
            <div className="relative
                            lg:scale-100
                            md:scale-150
                            sm:scale-[200%]
                            ">
                <img
                    alt="truck img"
                    style={{
                        position: 'absolute',
                        transform: isTruckAnimated ? 'translate(40%, 25%) ' : 'translate(calc(-25vw), calc(-20%))',
                        transition: 'transform 2.3s cubic-bezier(0.42, 1.25, 1, 1) 0s'
                    }}
                    src={truck}    className="truck-animation relative flex z-20
                                                lg:pt-[8vh]
                                                md:pt-[4vh]
                                                sm:pt-[1vh]
                                                " />
            </div>
            <div className="relative
                            lg:scale-100
                            md:scale-150
                            sm:scale-[200%]
                            ">
                <img
                    alt="truck img"
                    style={{
                        position: 'absolute',
                        transform: isTruckAnimated ? 'translate(30%, 35%) ' : 'translate(calc(-30vw), calc(-10%))',
                        transition: 'transform 2s cubic-bezier(0.42, 1.25, 1, 1) 0s'
                    }}
                    src={truck}    className="truck-animation relative flex z-20
                                                lg:pt-[8vh]
                                                md:pt-[4vh]
                                                sm:pt-[1vh]
                                                " />
            </div>

            <img src={union1} alt="background image" className=' bg-no-repeat absolute
                                                                 lg:scale-100 lg:mt-[30vh]
                                                                 md:scale-75  md:mt-[20vh]
                                                                 sm:scale-50  sm:mt-[15vh] sm:-left-[15vw]
                                                                 '/>
            <h2 data-aos="fade-up" data-aos-delay="50" className="my-14 text-center text-[#333333] font-[600]
                                                                  lg:text-6xl lg:leading-relaxed
                                                                  md:text-5xl md:leading-relaxed
                                                                  sm:text-2xl sm:leading-10
                                                                 ">
                <span className="text-amber-500">날개물류</span>는<br/>
                위탁 배송과는 차별화된<br/>
                안정적인 서비스를 제공합니다.
            </h2>

                */}


             <div className="relative">
                <div className="bg-[#242733] flex justify-center items-center w-[100vw]
                                        lg:min-h-[650px]
                                        md:min-h-[500px]
                                        sd:min-h-[500px] ">
                        <Lottie options={defaultOptions}/>
                </div>
            </div>


{/*            <div className="relative">
                <div className="bg-[#242733] flex justify-center items-center
                                        lg:min-h-[810px]
                                        md:min-h-[720px]
                                        sd:min-h-[576px]  sm:min-w-[320px]  ">
                    <img src={map1} alt="background image" className=' bg-no-repeat '/>
                </div>
            </div>*/}
        </>
    );
}

export default Home4;
