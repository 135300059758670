import React, {useEffect} from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import '../src/styles/index.css';
import NavBar from './components/Navbar/NavBar';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/home/Home';
import Contact from './pages/qna/section/Contact';
import About from './pages/about/About';
import IntroCoreValue from './pages/vision/Vision';
import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import History from "./pages/history/History";
import QnA from "./pages/qna/QnA";
import FAQ from "./pages/faq/FAQ";
import Footer from "./components/Footer";
import Notice from "./pages/notice/Notice";
import Notice3 from "./pages/notice/section/Notice3";
import Chart from "./pages/chart/Chart";
import NalgaeTv from "./pages/nalgaetv/NalgaeTv";


function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("The Best Book Logistics ::날개:: ");

  return (
      <>
        <Router>
          <NavBar />
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/qna" element={<QnA />} />
              <Route path="/intro" element={<About />} />
              <Route path="/vision" element={<IntroCoreValue />} />
              <Route path="/history" element={<History />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/chart" element={<Chart />} />
              <Route path="/notice" element={<Notice />} />
              <Route path="/notice3" element={<Notice3 />} />
              <Route path="/nalgaetv" element={<NalgaeTv />} />
            </Routes>
          </ScrollToTop>
          <Footer />
        </Router>
      </>
  );
}

export default App;
