import React, { useEffect } from 'react';
import NalgaeTv from './section/NalgaeTv';

const nalgaeTv = () => {
    return (
        <>
            <div>
                <NalgaeTv/>
            </div>
        </>
    )
}

export default nalgaeTv;
