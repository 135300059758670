import React, {useState, useEffect, useRef} from 'react';
import {Statistics4} from "../../chart/statistics/Statistics4";


const Home5 = () => {
    const [count, setCount] = useState(1);
    // const [count2, setCount2] = useState(1);
    const [count3, setCount3] = useState(1);
    const [count4, setCount4] = useState(1);
    const [count5, setCount5] = useState(1);
    const targetRef = useRef(null);
    // const targetRef2 = useRef(null);
    const targetRef3 = useRef(null);
    const targetRef4 = useRef(null);
    const targetRef5 = useRef(null);
    const [intersected, setIntersected] = useState(false);
    // const [intersected2, setIntersected2] = useState(false);
    const [intersected3, setIntersected3] = useState(false);
    const [intersected4, setIntersected4] = useState(false);
    const [intersected5, setIntersected5] = useState(false);

    useEffect(() => {
        if (!intersected && targetRef.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected) {
                        setIntersected(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected]);

    /*useEffect(() => {
        if (!intersected2 && targetRef2.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected2) {
                        setIntersected2(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef2.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected2]);
*/
    useEffect(() => {
        if (!intersected3 && targetRef3.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected3) {
                        setIntersected3(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef3.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected3]);

    useEffect(() => {
        if (!intersected4 && targetRef4.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected4) {
                        setIntersected4(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef4.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected4]);

    useEffect(() => {
        if (!intersected5 && targetRef5.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected5) {
                        setIntersected5(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef5.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected5]);

    useEffect(() => {
        if (intersected) {
            const interval = setInterval(() => {
                setCount(prevCount => {
                    if (prevCount < 150) {
                        return prevCount + 1;
                    } else {
                        clearInterval(interval);
                        return prevCount;
                    }
                });
            }, 20);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected]);

    /*useEffect(() => {
        if (intersected2) {
            const interval = setInterval(() => {
                setCount2(prevCount => {
                    if (prevCount < 6400) {
                        return prevCount + 99;
                    } else {
                        clearInterval(interval);
                        return 6400;
                    }
                });
            }, 38);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected2]);*/

    useEffect(() => {
        if (intersected3) {
            const interval = setInterval(() => {
                setCount3(prevCount => {
                    if (prevCount < 4) {
                        return prevCount + 1;
                    } else {
                        clearInterval(interval);
                        return 4;
                    }
                });
            }, 400);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected3]);

    useEffect(() => {
        if (intersected4) {
            const interval = setInterval(() => {
                setCount4(prevCount => {
                    if (prevCount < 4800) {
                        return prevCount + 100;
                    } else {
                        clearInterval(interval);
                        return 4800;
                    }
                });
            }, 30);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected4]);

    useEffect(() => {
        if (intersected5) {
            const interval = setInterval(() => {
                setCount5(prevCount => {
                    if (prevCount < 99.9) {
                        return prevCount + 0.2;
                    } else {
                        clearInterval(interval);
                        return 99.9;
                    }
                });
            }, 3);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected5]);

    return (
        <>
            <div className=" overflow-x-hidden overflow-y-hidden
                            lg:min-h-[700px] lg:max-h-[800px]
                            md:min-h-[600px] md:max-h-[650px]
                            sm:min-h-[400px] sm:max-h-[450px] sm:min-w-[320px]">


                <style>
                    {`
                        .over-show {
                        }
                    `}
                </style>


                <div className="text-center">
                    <div data-aos="fade-right" data-aos-delay="1000" className='absolute z-[5] overflow-x-hidden
                                    lg:w-[100vw] lg:h-[800px]
                                    md:w-[100vw] md:h-[700px]
                                    sm:w-[100vw] sm:h-[550px] '>
                        <Statistics4/>
                    </div>
                </div>
                <h2 className="text-[#333333]
                                    lg:text-[44px] lg:leading-[58px] lg:font-[700] lg:text-[#333333] lg:px-[120px] lg:pt-[120px]
                                    md:text-[44px] md:leading-[58px] md:font-[700] md:text-[#333333] md:px-[80px]  md:py-[80px]
                                    sm:text-[23px] sm:leading-[36px] sm:font-[700] sm:text-[#333333] sm:px-[40px]  sm:py-[40px]
                                    ">
                    숫자로 보는 날개 <span className="text-gray-500
                                                       lg:text-[18px]
                                                       md:text-[18px]
                                                       sm:text-[14px]">({new Date().getFullYear()}년 기준)</span>
                </h2>
                <div className="mx-[10vw] flex justify-end  " data-aos="fade-up" data-aos-delay="10">
                    <div className="mb-[1vh] grid  w-full
                                       lg:gap-0  lg:grid-cols-4 lg:pt-[50px]
                                       md:gap-6  md:grid-cols-3 md:pb-[20px]
                                       sm:gap-4  sm:grid-cols-3 sm:pb-[20px] ">

                        {/*    카드1    */}
                        <div className="text-gray-700  bg-transparent " ref={targetRef}>
                            <div className="text-justify ">
                                <div className="lg:text-[20px] lg:leading-[24px] lg:font-[400] lg:text-[#464646]
                                                md:text-[20px] md:leading-[24px] md:font-[400] md:text-[#464646]
                                                sm:text-[10px] sm:leading-[22px] sm:font-[400] sm:text-[#464646] sm:min-w-[200px]">
                                    일일 최대 소화량
                                </div>
                                <h4 className="font-bold text-blue-900 h-auto
                                                   lg:text-[36px] lg:leading-[52px] lg:font-[700] lg:text-[#333333]
                                                   md:text-[24px] md:leading-[48px] md:font-[700] md:text-[#333333]
                                                   sm:text-[13px] sm:leading-[20px] sm:font-[700] sm:text-[#333333]">
                                        <span className="inline-block
                                                         lg:w-[40px] lg:mr-[20px]
                                                         md:w-[30px] md:mr-[10px]
                                                         sm:w-[25px]
                                                         ">{count.toLocaleString()}</span>만부
                                </h4>
                            </div>
                        </div>

                        {/*    카드2    */}
                        <div className="text-gray-700 bg-transparent ">
                            <div className="text-justify ">
                                <h4 className="lg:text-[20px] lg:leading-[24px] lg:font-[400] lg:text-[#464646]
                                                   md:text-[20px] md:leading-[24px] md:font-[400] md:text-[#464646]
                                                   sm:text-[10px] sm:leading-[22px] sm:font-[400] sm:text-[#464646]">
                                    파레트 보관 수
                                </h4>
                                <h4 className="font-bold text-blue-900 h-auto
                                               lg:text-[36px] lg:leading-[52px] lg:font-[700] lg:text-[#333333]
                                               md:text-[24px] md:leading-[48px] md:font-[700] md:text-[#333333]
                                               sm:text-[13px] sm:leading-[20px] sm:font-[700] sm:text-[#333333]">
                                    4만 5천장
                                </h4>
                            </div>
                        </div>

                        {/*    카드3    */}
                        <div className=" text-gray-700  lg:min-w-[400px] bg-transparent ">
                            <div className="text-justify ">
                                <h4 className="lg:text-[20px] lg:leading-[24px] lg:font-[400] lg:text-[#464646]
                                                   md:text-[20px] md:leading-[24px] md:font-[400] md:text-[#464646]
                                                   sm:text-[10px] sm:leading-[22px] sm:font-[400] sm:text-[#464646] sm:min-w-[200px]">
                                    연간 최대 소화량
                                </h4>
                                <h4 className="font-bold text-blue-900 h-auto
                                                   lg:text-[36px] lg:leading-[52px] lg:font-[700] lg:text-[#333333]
                                                   md:text-[24px] md:leading-[48px] md:font-[700] md:text-[#333333] md:min-w-[300px]
                                                   sm:text-[13px] sm:leading-[20px] sm:font-[700] sm:text-[#333333] sm:min-w-[300px]">
                                    3억 6천만부
                                    <span
                                        className="text-gray-500 lg:text-[18px] md:text-[18px] sm:text-[12px] sm:block sm:absolute sm:leading-[10px]"> (일 150만부)</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-[10vw] flex justify-end   " data-aos="fade-up" data-aos-delay="10">
                    <div className="mb-[1vh] grid  w-full
                                       lg:gap-0 lg:grid-cols-4  lg:pb-[40px]
                                       md:gap-6  md:grid-cols-3 md:pb-[20px]
                                       sm:gap-4  sm:grid-cols-3 sm:pb-[20px]">
                        {/*    카드4    */}
                        <div className=" text-gray-700  lg:min-w-[400px]  bg-transparent ">
                            <div className="text-justify ">
                                <h4 className="lg:text-[20px] lg:leading-[24px] lg:font-[400] lg:text-[#464646]
                                                   md:text-[20px] md:leading-[24px] md:font-[400] md:text-[#464646]
                                                   sm:text-[10px] sm:leading-[22px] sm:font-[400] sm:text-[#464646]">
                                    출고 정확도
                                </h4>
                                <h4 className="font-bold text-blue-900 h-auto
                                                   lg:text-[36px] lg:leading-[52px] lg:font-[700] lg:text-[#333333]
                                                   md:text-[24px] md:leading-[48px] md:font-[700] md:text-[#333333]
                                                   sm:text-[13px] sm:leading-[20px] sm:font-[700] sm:text-[#333333]">
                                        <span className="inline-block
                                                         lg:w-[1vw] lg:mr-[10px] lg:w-[70px]
                                                         md:w-[2vw] md:mr-[10px] md:w-[40px]
                                                         sm:w-[3vw] sm:mr-[20px] sm:w-[35px]
                                                         ">{count5.toLocaleString()}</span><span className=''>%</span>
                                </h4>
                            </div>
                        </div>

                        {/*    카드5    */}
                        <div className=" text-gray-700  lg:min-w-[400px] bg-transparent ">
                            <div className="text-justify ">
                                <h4 className="lg:text-[20px] lg:leading-[24px] lg:font-[400] lg:text-[#464646]
                                                   md:text-[20px] md:leading-[24px] md:font-[400] md:text-[#464646]
                                                   sm:text-[10px] sm:leading-[22px] sm:font-[400] sm:text-[#464646]">
                                    재고 로스율
                                </h4>
                                <h4 className="font-bold text-blue-900 h-auto
                                                   lg:text-[36px] lg:leading-[52px] lg:font-[700] lg:text-[#333333]
                                                   md:text-[24px] md:leading-[48px] md:font-[700] md:text-[#333333]
                                                   sm:text-[13px] sm:leading-[20px] sm:font-[700] sm:text-[#333333]">
                                    <span className=''>0.07 % 이하</span>
                                </h4>
                            </div>
                        </div>

                        {/*    카드6    */}
                        <div className=" text-gray-700  lg:min-w-[400px] bg-transparent " ref={targetRef5}>
                            <div className="text-justify ">
                                <h4 className="lg:text-[20px] lg:leading-[24px] lg:font-[400] lg:text-[#464646]
                                                   md:text-[20px] md:leading-[24px] md:font-[400] md:text-[#464646]
                                                   sm:text-[10px] sm:leading-[22px] sm:font-[400] sm:text-[#464646]">
                                    물류 센터 규모
                                </h4>
                                <h4 className="font-bold text-blue-900 h-auto
                                                   lg:text-[36px] lg:leading-[52px] lg:font-[700] lg:text-[#333333]
                                                   md:text-[24px] md:leading-[48px] md:font-[700] md:text-[#333333] md:min-w-[300px]
                                                   sm:text-[13px] sm:leading-[20px] sm:font-[700] sm:text-[#333333] sm:min-w-[300px]">
                                     <span className=''>축구장 8개 크기
                                       <span
                                           className="text-gray-500 lg:text-[18px] md:text-[18px] sm:text-[12px] sm:block sm:absolute sm:leading-[10px]"> (건평 2만평)</span>
                                     </span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mx-[10vw] flex justify-end   " data-aos="fade-up" data-aos-delay="10">
                    <div className="mb-[1vh] grid  w-full
                                        lg:gap-0 lg:grid-cols-4  lg:pb-[40px]
                                        md:gap-6  md:grid-cols-3 md:pb-[20px]
                                        sm:gap-4  sm:grid-cols-3 sm:pb-[20px]">
                        {/*    카드7    */}
                        <div className="text-gray-700 bg-transparent  ">
                            <div className="text-justify ">
                                <h4 className="lg:text-[20px] lg:leading-[24px] lg:font-[400] lg:text-[#464646]
                                               md:text-[20px] md:leading-[24px] md:font-[400] md:text-[#464646]
                                               sm:text-[10px] sm:leading-[22px] sm:font-[400] sm:text-[#464646]">
                                    배송 거래 출판사 수
                                </h4>
                                <h4 className="font-bold text-blue-900 h-auto
                                               lg:text-[36px] lg:leading-[52px] lg:font-[700] lg:text-[#333333]
                                               md:text-[24px] md:leading-[48px] md:font-[700] md:text-[#333333]
                                               sm:text-[13px] sm:leading-[20px] sm:font-[700] sm:text-[#333333] "
                                    ref={targetRef4}>
                                    <span className="">{count4.toLocaleString()} <span
                                        className="sm:ml-[5px]">여개사</span> </span>
                                </h4>
                            </div>
                        </div>
                        {/*
                               <div className=" text-gray-700  lg:min-w-[400px] bg-transparent " ref={targetRef5}>
                                    <div className="text-justify ">
                                        <h4 className="lg:text-[20px] lg:leading-[24px] lg:font-[400] lg:text-[#464646]
                                                       md:text-[20px] md:leading-[24px] md:font-[400] md:text-[#464646]
                                                       sm:text-[10px] sm:leading-[22px] sm:font-[400] sm:text-[#464646]">
                                            전국 직접 배송가능 서점수
                                        </h4>
                                        <h4 className="font-bold text-blue-900 h-auto
                                                       lg:text-[36px] lg:leading-[52px] lg:font-[700] lg:text-[#333333]
                                                       md:text-[24px] md:leading-[48px] md:font-[700] md:text-[#333333]
                                                       sm:text-[13px] sm:leading-[20px] sm:font-[700] sm:text-[#333333]">
                                         <span className=''>OOOO여개사</span>
                                        </h4>
                                    </div>
                                </div>
                            */}

                        {/*    카드8--   */}
                        <div className="text-gray-700 bg-transparent ">
                            <div className="text-justify ">
                                <h4 className="lg:text-[20px] lg:leading-[24px] lg:font-[400] lg:text-[#464646]
                                               md:text-[20px] md:leading-[24px] md:font-[400] md:text-[#464646]
                                               sm:text-[10px] sm:leading-[22px] sm:font-[400] sm:text-[#464646]">
                                    화물 차량
                                </h4>
                                <h4 className="font-bold text-blue-900 h-auto
                                               lg:text-[36px] lg:leading-[52px] lg:font-[700] lg:text-[#333333]
                                               md:text-[24px] md:leading-[48px] md:font-[700] md:text-[#333333]
                                               sm:text-[13px] sm:leading-[20px] sm:font-[700] sm:text-[#333333] sm:min-w-[300px]">
                                   <span className=''>100여대
                                      <span
                                          className="text-gray-500 lg:text-[18px] md:text-[18px] sm:text-[12px] sm:block sm:absolute sm:leading-[10px]"> (11t 이하)</span>
                                   </span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home5;
