import React from 'react';
import Vision1 from "./section/Vision1";
import IntroCoreValue2 from "./section/Vision2";

const Introduce = () => {
    return (
        <>
            <div>
                <Vision1/>
                <IntroCoreValue2/>
            </div>
        </>

    )
}

export default Introduce;

