import React, { useState } from 'react';
import QnA3 from "./QnA3";
import QnA2 from "./QnA2";
import QnA4 from "./QnA4";

const IntroCoreValue1 = () => {
    const [selectedCategory, setSelectedCategory] = useState('delivery');

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    }

    let selectedComponent;
    if (selectedCategory === 'delivery') {
        selectedComponent = <QnA3/>;
    } else if (selectedCategory === 'warehouse') {
        selectedComponent = <QnA2/>;
    } else {
        selectedComponent = <QnA4/>;
    }

    return (
        <>
            <div className="ml-[20vw] mt-[15vh] h-full
                            sm:ml-[15vw] overflow-x-hidden
            ">
                <div className='font-bold w-auto mb-[20px]
                                lg:text-[50px] lg:leading-[60px]
                                md:text-[44px] md:leading-[56px]
                                sm:text-[40px] md:leading-[48px] '>
                    고객문의
                </div>
                <div className='text-gray-600 lg:w-auto break-keep
                                lg:text-[22px] lg:leading-[32px]  lg:mb-[10vh]
                                md:text-[18px] md:leading-[32px]  md:mb-[10vh] md:w-[600px]
                                sm:text-[16px] sm:leading-[26px]  sm:mb-[10vh]'>
                    거래 상담, 견적 요청을 원하시는 경우 아래 내용을 작성하여 주시기 바랍니다.<br/>
                    담당자가 바로 연락드리겠습니다.
                </div>


            </div>
            <div className="text-center">
                <div className='font-[700]  text-[#172041]
                                    lg:text-[31px]  lg:leading-[32px]  lg:mb-[2vh]
                                    md:text-[31px]  md:leading-[32px]  md:mb-[2vh]
                                    sm:text-[26px]  sm:leading-[26px]  sm:mb-[6vh] '>
                    <span className='mx-[2vw] '>문의 유형을 선택해 주세요.</span>
                </div>
                <div className=''>
                    <button onClick={() => handleCategoryChange('warehouse')}
                            className= {`category-button ${selectedCategory === 'warehouse' ? 'active selected' : ''} mx-[0.7vw] bg-white border-[1px] border-[#16196E] transition-all ease-in-out duration-400 text-[#777777} hover:bg-[#2041B4] hover:text-white rounded-2xl group`}
                            style={{
                                backgroundColor: selectedCategory === 'warehouse' ? '#16196E' : 'white',
                                color: selectedCategory === 'warehouse' ? 'white' : '#777777'
                            }}>
                        <h2 className="font-[500] w-[120px] my-4 text-center
                                       lg:text-[18px] lg:leading-[21px] lg:w-[120px]
                                       md:text-[16px] md:leading-[21px] md:w-[100px]
                                       sm:text-[14px] sm:leading-[19px] sm:w-[80px]">배송 위탁</h2>
                    </button>

                    <button onClick={() => handleCategoryChange('delivery')}
                            className={`category-button ${selectedCategory === 'delivery' ? 'active selected' : ''} mx-[0.7vw] bg-white  border-[1px] border-[#16196E] transition-all ease-in-out duration-400 text-[#777777} hover:bg-[#2041B4] hover:text-white rounded-2xl group`}
                            style={{
                                backgroundColor: selectedCategory === 'delivery' ? '#16196E' : 'white',
                                color: selectedCategory === 'delivery' ? 'white' : '#777777'
                            }}>
                        <h2 className="font-[500] w-[120px] my-4 text-center
                                       lg:text-[18px] lg:leading-[21px] lg:w-[120px]
                                       md:text-[16px] md:leading-[21px] md:w-[100px]
                                       sm:text-[14px] sm:leading-[19px] sm:w-[80px]">창고 위탁</h2>
                    </button>

                    <button onClick={() => handleCategoryChange('etc')}
                            className={`category-button ${selectedCategory === 'etc' ? 'active selected' : ''} mx-[0.7vw] bg-white  border-[1px] border-[#16196E] transition-all ease-in-out duration-400 text-[#777777} hover:bg-[#2041B4] hover:text-white rounded-2xl group`}
                            style={{
                                backgroundColor: selectedCategory === 'etc' ? '#16196E' : 'white',
                                color: selectedCategory === 'etc' ? 'white' : '#777777'
                            }}>
                        <h2 className="font-[500] w-[120px] my-4 text-center
                                       lg:text-[18px] lg:leading-[21px] lg:w-[120px]
                                       md:text-[16px] md:leading-[21px] md:w-[100px]
                                       sm:text-[14px] sm:leading-[19px] sm:w-[80px]">기타</h2>
                    </button>
                </div>

            </div>
            <div>
                {selectedComponent}
            </div>
        </>
    );
}

export default IntroCoreValue1;
