import React, {useEffect, useState} from "react";
import union1 from "../../../images/logo/Union1.png";
import axios from "axios";
import "./QnA.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pan from '../../../images/logo/pan.jpg'

const QnA3 = () => {
    const [formData, setFormData] = useState({
        category: "창고 위탁",
        company: "",
        jopPosition:"",
        contactPerson: "",
        email: "",
        phoneNumber: "",
        genre: [], // 출간 형태 다수로
        averagePrice: "", // 도서 평균단가
        averagePlateType: "", // 도서 평균판형
        currentStock: "", // 현 보유 재고
        types: "", // 종수
        expectedQuantity: "", // 예상 출고 부수(월)
        returnAgreement: "", // sm:w-[230px]탁여부
        existingWarehouse: "", // 기존 창고 위치
        previouslyMethod: [], // 기존출고방식
        desiredPrice1: "", // 희망단가(보관 부수당)
        desiredPrice2: "", // 희망단가(출고 부수당)
        content: "" // 기타요청사항
    });

    const [isMobile, setIsMobile] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (modalOpen) {
            // 모달이 열릴 때 body에 overflow-hidden 클래스 추가
            document.body.classList.add('overflow-hidden');
        } else {
            // 모달이 닫힐 때 body에 추가된 overflow-hidden 클래스 제거
            document.body.classList.remove('overflow-hidden');
        }
    }, [modalOpen]);

    const notify = () => toast("문의가 접수되었습니다");
    const yesify = () => toast("문의가 접수되었습니다");

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        // 체크박스인 경우
        if (type === "checkbox") {
            // 체크가 되었을 때는 해당 값 추가, 해제되었을 때는 제거
            setFormData(prevState => ({
                ...prevState,
                [name]: checked ? [...prevState[name], value] : prevState[name].filter(item => item !== value)
            }));
        } else {
            // 일반적인 입력 필드의 경우
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const resetForm3 = () => {
        setFormData({
            category: "창고 위탁",
            company: "",
            jopPosition:"",
            contactPerson: "",
            email: "",
            phoneNumber: "",
            genre: [], // 출간 형태 다수로
            averagePrice: "", // 도서 평균단가
            averagePlateType: "", // 도서 평균판형
            currentStock: "", // 현 보유 재고
            types: "", // 종수
            expectedQuantity: "", // 예상 출고 부수(월)
            returnAgreement: "", // sm:w-[230px]탁여부
            existingWarehouse: "", // 기존 창고 위치
            previouslyMethod: [], // 기존출고방식
            desiredPrice1: "", // 희망단가(보관 부수당)
            desiredPrice2: "", // 희망단가(출고 부수당)
            content: "" // 기타요청사항
        });
    };


    const qnaSubmit = () => {
        if (!formData.company.trim() || !formData.phoneNumber.trim()) {
            alert("필수 입력란을 모두 입력해주세요.");
            return;
        }
        if (!document.getElementById('agreePrivacy').checked) {
            alert("개인정보 수집 및 이용에 동의하지 않으셨습니다. 동의 후에 문의를 진행해주세요.");
            return;
        }
        const qnaData = {
            "type": "message",
            "attachments": [
                {
                    "contentType": "application/vnd.microsoft.card.adaptive",
                    "contentUrl": null,
                    "content": {
                        "type": "AdaptiveCard",
                        "body": [
                            {
                                "type": "ColumnSet",
                                "columns": [
                                    {
                                        "type": "Column",
                                        "items": [
                                            {
                                                "type": "Image",
                                                "style": "Person",
                                                "url": "https://img.freepik.com/free-vector/3d-blue-notification-mark-symbol-and-social-media-communication-sign-icon-on-alert-danger-problem_40876-3476.jpg",
                                                "altText": "QnA",
                                                "size": "Medium"
                                            }
                                        ],
                                        "width": "auto"
                                    },
                                    {
                                        "type": "Column",
                                        "items": [
                                            {
                                                "type": "TextBlock",
                                                "weight": "Bolder",
                                                "text": "홈페이지 문의하기",
                                                "wrap": true
                                            }
                                        ],
                                        "width": "stretch"
                                    }
                                ]
                            },
                            {
                                "type": "Table",
                                "columns": [
                                    {
                                        "width": 1.2
                                    },
                                    {
                                        "width": 2
                                    }
                                ],
                                "rows": [
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"문의분야","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [ {   "type": "TextBlock",
                                                    text: `${formData.category}`,
                                                    "isSubtle": true,
                                                    "wrap": true,
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"업체명","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [  {   "type": "TextBlock",
                                                    text: `${formData.company}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    }, {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"직위","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [  {   "type": "TextBlock",
                                                    text: `${formData.jopPosition}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"담당자명","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [  {   "type": "TextBlock",
                                                    text: `${formData.contactPerson}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"연락처","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [ {   "type": "TextBlock",
                                                    text: `${formData.phoneNumber}`,
                                                    "isSubtle": true,
                                                    "wrap": true,

                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"이메일","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [ {   "type": "TextBlock",
                                                    text: `${formData.email}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"출간 형태","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [  {   "type": "TextBlock",
                                                    text: `${formData.genre}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"도서 평균단가","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [ {   "type": "TextBlock",
                                                    text: `${formData.averagePrice}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"도서 평균판형","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [  {   "type": "TextBlock",
                                                    text: `${formData.averagePlateType}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"현 보유 재고","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [{   "type": "TextBlock",
                                                    text: `${formData.currentStock}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"종수","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [{   "type": "TextBlock",
                                                    text: `${formData.types}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"예상 출고 부수(월)","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [ {   "type": "TextBlock",
                                                    text: `${formData.expectedQuantity}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"반품 위탁 여부","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [ {   "type": "TextBlock",
                                                    text: `${formData.returnAgreement}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"기존 창고 위치","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [{   "type": "TextBlock",
                                                    text: `${formData.existingWarehouse}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"기존출고방식","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [  {   "type": "TextBlock",
                                                    text: `${formData.previouslyMethod}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"희망단가(보관부수당)","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [{   "type": "TextBlock",
                                                    text: `${formData.desiredPrice1}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type":"TextBlock","text":"희망단가(출고부수당)","weight":"Bolder","wrap":true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [ {   "type": "TextBlock",
                                                    text: `${formData.desiredPrice2}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "type": "TableRow",
                                        "cells": [
                                            {
                                                "type": "TableCell",
                                                "items": [{"type": "TextBlock","text": "기타요청사항","weight": "Bolder","wrap": true}
                                                ]
                                            },
                                            {
                                                "type": "TableCell",
                                                "items": [{"type": "TextBlock",
                                                    text: `${formData.otherRequests}`,
                                                    "isSubtle": true,
                                                    "wrap": true
                                                }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            },
                        ],
                        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
                        "version": "1.6"
                    }
                }
            ]
        };

        //테스트웹훅 var webhookUrl= "https://nalgae.webhook.office.com/webhookb2/a46effe5-4040-4e51-9ebe-f5e869e60db1@9afce5e9-0f70-47c4-b2ce-7174e3b88c03/IncomingWebhook/0153d44ca84146adb47006cd397f3428/7e05e48f-59de-4c1e-a54f-d6ba09b392c8";
        var webhookUrl = "https://nalgae.webhook.office.com/webhookb2/39cae91a-b311-4b40-9273-8676da3e2b17@9afce5e9-0f70-47c4-b2ce-7174e3b88c03/IncomingWebhook/0359ee813c6848c5822fdb1acdffa9d5/7e05e48f-59de-4c1e-a54f-d6ba09b392c8/V2WtV-bHH8hA2qKnUxU7syletQCaW4Jm4jhV4eNWsfoak1";
        alert("문의가 발송되었습니다.");

        axios.post(webhookUrl, qnaData, {
            headers: {
                'Content-Type': 'text/plain'
            }
        })
            .then(res => {
                {notify()}
                resetForm3();
            })
            .catch(error => {
                {yesify()}
                resetForm3();
            });
    };



    return (
        <>
            <div className="overflow-x-hidden">
                <img src={union1} alt="background image" className='absolute left-0 z-0 pointer-events-none
                                                                    lg:scale-100 lg:ml-[0vw]   lg:mt-[20vh] lg:opacity-60
                                                                    md:scale-75  md:-ml-[10vw] md:mt-[15vh] md:opacity-70
                                                                    sm:scale-50  sm:-ml-[20vw] sm:mt-[15vh] sm:opacity-70'/>


                <div id='QA' className="flex flex-col w-full
                                        lg:mt-[48px]
                                        md:mt-[48px]
                                        sm:mt-[40px]">
                    <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                        <div className="">
                            <label htmlFor="pastWay" className="text-[#6B7280] text-[14px] leading-[16px]">업체명</label>
                            <span className="text-red-400 text-[10px] ml-[10px]"> * 필수 입력란입니다.</span>
                            <input type="text" id="company" placeholder="업체명을 입력해 주세요." name="company"
                                   value={formData.company}
                                   onChange={handleChange}
                                   className="lg:w-[54rem] md:w-[440px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]
                                          sm:w-[26.5rem] sm:mr-4
                               "/>
                        </div>
                    </div>
                    <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                        <div className="mr-[20px] lg:mb-[0px] md:mb-[24px] lg:mb-[0px] md:mb-[24px] sm:mb-[24px]">
                            <label htmlFor="jopPosition"
                                   className="text-[#6B7280] text-[14px] leading-[16px] mb-[8px]">직위</label>
                            <input type="text" id="jopPosition" placeholder="담당자의 직위를 입력해 주세요." name="jopPosition"
                                   value={formData.jopPosition} onChange={handleChange}
                                   className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                        </div>
                        <div>
                            <label htmlFor="contactPerson"
                                   className="text-[#6B7280] text-[14px] leading-[16px]">담당자</label>
                            <input type="text" id="contactPerson" placeholder="담당자명을 입력해 주세요." name="contactPerson"
                                   value={formData.contactPerson} onChange={handleChange}
                                   className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                        </div>
                    </div>

                    <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                        <div className="mr-[20px] lg:mb-[0px] md:mb-[24px] sm:mb-[24px]">
                            <label htmlFor="email" className="text-[#6B7280] text-[14px] leading-[16px]">이메일</label>
                            <input type="text" id="email" placeholder="이메일을 입력해 주세요." name="email"
                                   value={formData.email}
                                   onChange={handleChange}
                                   className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                        </div>
                        <div>
                            <label htmlFor="phoneNumber"
                                   className="text-[#6B7280] text-[14px] leading-[16px]">연락처</label>
                            <span className="text-red-400 text-[10px] ml-[10px]"> * 필수 입력란입니다.</span>
                            <input type="text" id="phoneNumber" placeholder="연락처를 입력해 주세요." name="phoneNumber"
                                   value={formData.phoneNumber} onChange={handleChange}
                                   className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                        </div>
                    </div>

                    <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                        <div className="mr-[20px] lg:mb-[0px] md:mb-[0px] sm:mb-[0px]">
                            <label htmlFor="genre"
                                   className="text-[#6B7280] text-[14px] leading-[16px]">출간 형태</label>
                            <div className='flex mr-2 my-[2vh]
                                            lg:flex-row lg:w-[420px]
                                            md:flex-row md:w-[420px]
                                            sm:flex-col sm:w-[340px]'>
                                <div className='mr-[10px] lg:text-lg sm:text-sm min-w-[70px] sm:ml-[5px]'>
                                    <input type="checkbox" id="danhang" name="genre" value="단행본"
                                           checked={formData.genre.includes('단행본')} onChange={handleChange}/>
                                    <label className={"ml-2"} htmlFor="danhang">단행본</label>
                                </div>
                                <div className='mr-[10px] lg:text-lg sm:text-sm min-w-[70px] sm:ml-[5px]'>
                                    <input type="checkbox" id="adong" name="genre" value="아동류"
                                           checked={formData.genre.includes('아동류')} onChange={handleChange}/>
                                    <label className={"ml-2"} htmlFor="adong">아동류</label>
                                </div>
                                <div className='mr-[10px] lg:text-lg sm:text-sm min-w-[85px] sm:ml-[5px]'>
                                    <input type="checkbox" id="jeonmun" name="genre" value="전문서적"
                                           checked={formData.genre.includes('전문서적')} onChange={handleChange}/>
                                    <label className={"ml-2"} htmlFor="jeonmun">전문서적</label>
                                </div>
                                <div
                                    className='mr-[10px] lg:text-lg sm:text-sm min-w-[60px] sm:row-start-2 sm:ml-[5px]'>
                                    <input type="checkbox" id="eohak" name="genre" value="어학"
                                           checked={formData.genre.includes('어학')} onChange={handleChange}/>
                                    <label className={"ml-2"} htmlFor="eohak">어학</label>
                                </div>
                                <div
                                    className='mr-[10px] lg:text-lg sm:text-sm min-w-[70px] sm:row-start-2 sm:ml-[5px] '>
                                    <input type="checkbox" id="chamgo" name="genre" value="참고서"
                                           checked={formData.genre.includes('참고서')} onChange={handleChange}/>
                                    <label className={"ml-2"} htmlFor="chamgo">참고서</label>
                                </div>
                            </div>
                        </div>
                        <div className="lg:block md:hidden sm:hidden pointer-events-none">
                            <label className="text-[#6B7280] text-[14px] leading-[16px]"></label>
                            <input type="text" readOnly={true} tabIndex="-1"
                                   className=" pointer-events-none w-[420px] sm:w-[345px] p-[10px] border border-transparent block text-[14px] leading-[16px]"/>
                        </div>
                    </div>

                    <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                        <div className="mr-[20px] lg:mb-[0px] md:mb-[24px] sm:mb-[24px]">
                            <label htmlFor="averagePrice"
                                   className="text-[#6B7280] text-[14px] leading-[16px]">도서 평균단가</label>
                            <input type="text" id="averagePrice" placeholder="도서 평균단가를 입력해 주세요." name="averagePrice"
                                   value={formData.averagePrice} onChange={handleChange}
                                   className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                        </div>
                        <div>

                            <label
                                htmlFor="averagePlateType"
                                className="text-[#6B7280] text-[14px] leading-[16px] group"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                도서 평균판형
                                <FontAwesomeIcon
                                    icon={faQuestionCircle}
                                    className="ml-[3px] cursor-pointer text-[#16196E] group-hover:text-black"
                                />
                                {isHovered && (
                                    <img
                                        className="fixed top-1/2 transform -translate-y-1/2 right-0 bg-white border border-gray-400 p-2 z-0 scale-50"
                                        src={pan}
                                        alt="도서 평균판형 이미지"
                                    />
                                )}
                            </label>

                            <input type="text" id="averagePlateType" placeholder="도서 평균판형을 입력해 주세요."
                                   name="averagePlateType"
                                   value={formData.averagePlateType} onChange={handleChange}
                                   className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>

                        </div>
                    </div>

                    <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                        <div className="mr-[20px] lg:mb-[0px] md:mb-[24px] sm:mb-[24px]">
                            <label htmlFor="currentStock"
                                   className="text-[#6B7280] text-[14px] leading-[16px]">현 보유 재고</label>
                            <input type="text" id="currentStock" placeholder="현 보유 재고를 입력해 주세요." name="currentStock"
                                   value={formData.currentStock} onChange={handleChange}
                                   className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                        </div>
                        <div>
                            <label htmlFor="types" className="text-[#6B7280] text-[14px] leading-[16px]">종수</label>
                            <input type="text" id="types" placeholder="종수를 입력해 주세요." name="types" value={formData.types}
                                   onChange={handleChange}
                                   className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                        </div>
                    </div>


                    <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                        <div className="mr-[20px] lg:mb-[0px] md:mb-[0px] sm:mb-[0px]">
                            <label htmlFor="expectedQuantity"
                                   className="text-[#6B7280] text-[14px] leading-[16px]">예상 출고 부수(월)</label>
                            <input type="text" id="expectedQuantity" placeholder="예상 출고 부수(월)를 입력해 주세요."
                                   name="expectedQuantity" value={formData.expectedQuantity} onChange={handleChange}
                                   className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                        </div>
                        <div className="lg:block md:hidden sm:hidden pointer-events-none">
                            <label className="text-[#6B7280] text-[14px] leading-[16px]"></label>
                            <input type="text"
                                   className="w-[420px] sm:w-[345px] p-[10px] border border-transparent block text-[14px] leading-[16px]"/>
                        </div>
                    </div>


                    <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                        <div className="mr-[20px] lg:mb-[0px] md:mb-[0px] sm:mb-[0px]">
                            <label htmlFor="returnAgreement"
                                   className="text-[#6B7280] text-[14px] leading-[16px]">반품 위탁 여부</label>
                            <div className='flex mr-2 my-[2vh]
                                            lg:flex-row lg:w-[420px]
                                            md:flex-row md:w-[420px]
                                            sm:flex-row sm:w-[345px]'>
                                <div className='mr-[1vw] lg:text-lg in'>
                                    <input type="checkbox" id="returnTrue" name="returnAgreement" value="유"
                                           checked={formData.returnAgreement.includes('유')} onChange={handleChange}/>
                                    <label className={"ml-2"} htmlFor="returnAgreement">유</label>
                                </div>
                                <div className='mr-[1vw] lg:text-lg'>
                                    <input type="checkbox" id="returnFalse" name="returnAgreement" value="무"
                                           checked={formData.returnAgreement.includes('무')} onChange={handleChange}/>
                                    <label className={"ml-2"} htmlFor="returnAgreement">무</label>
                                </div>
                            </div>
                        </div>
                        <div className="lg:block md:hidden sm:hidden pointer-events-none">
                            <label className="text-[#6B7280] text-[14px] leading-[16px]"></label>
                            <input type="text" readOnly={true} tabIndex="-1"
                                   className=" pointer-events-none w-[420px] sm:w-[345px] p-[10px] border border-transparent block text-[14px] leading-[16px]"/>
                        </div>
                    </div>


                    <div
                        className={`m-auto mb-[20px] sm:max-w-[365px] defaultwarehouse lg:flex md:inline-block sm:inline-block`}>
                        <div className="mr-[25px] lg:mb-[0px] md:mb-[24px] sm:mb-[24px]">
                            <label htmlFor="existingWarehouse"
                                   className="text-[#6B7280] text-[14px] leading-[16px]">기존 창고 위치</label>
                            <input type="text" id="existingWarehouse" placeholder="기존 창고 위치를 입력해 주세요."
                                   name="existingWarehouse" value={formData.existingWarehouse} onChange={handleChange}
                                   className="w-[420px] sm:w-[345px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                        </div>

                        <div className="">
                            <label htmlFor="previouslyMethod" className="text-[#6B7280] text-[14px] leading-[16px]">
                                기존 출고방식
                            </label>
                            <div className='flex my-[1vh] w-[420px]
                                                lg:flex-row
                                                md:flex-row
                                                sm:flex-row'>
                                <div className='mr-[1vw] lg:text-lg'>
                                    <input type="checkbox" id="returnTrue" name="previouslyMethod" value="직접"
                                           checked={formData.previouslyMethod.includes('직접')} onChange={handleChange}/>
                                    <label className={"ml-2"} htmlFor="previouslyMethod">직접</label>
                                </div>
                                <div className='mr-[1vw] lg:text-lg'>
                                    <input type="checkbox" id="returnFalse" name="previouslyMethod" value="대행사"
                                           checked={formData.previouslyMethod.includes('대행사')} onChange={handleChange}/>
                                    <label className={"ml-2"} htmlFor="previouslyMethod">대행사</label>
                                </div>
                                <div className='mr-[1vw] lg:text-lg'>
                                    <input type="checkbox" id="returnFalse" name="previouslyMethod" value="화물/택배"
                                           checked={formData.previouslyMethod.includes('화물/택배')}
                                           onChange={handleChange}/>
                                    <label className={"ml-2"} htmlFor="previouslyMethod">화물/택배</label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={`m-auto mb-[20px] lg:flex md:inline-block sm:inline-block`}>
                        <div className="mr-[20px] lg:mb-[0px] md:mb-[04px] sm:mb-[0px]">
                            <label htmlFor="currentStock" className="text-[#6B7280] text-[14px] leading-[16px]">희망
                                단가(부수당)</label>
                            <div className="flex">
                                <span className="mr-[8px] sm:mr-[1px] sm:text-sm sm:min-w-[28px]">보관</span>
                                <input type="text" id="desiredPrice1" placeholder="희망단가" name="desiredPrice1"
                                       value={formData.desiredPrice1} onChange={handleChange}
                                       className="w-[390px] sm:w-[320px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="types"
                                   className="text-[#6B7280] text-[14px] leading-[16px]"> &nbsp;  </label>
                            <div className="flex">
                                <span className="mr-[8px] sm:mr-[1px] sm:text-sm sm:min-w-[28px]">출고</span>
                                <input type="text" id="desiredPrice2" placeholder="희망단가" name="desiredPrice2"
                                       value={formData.desiredPrice2}
                                       onChange={handleChange}
                                       className="w-[390px] sm:w-[320px] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                            </div>
                        </div>
                    </div>

                    <div className="m-auto flex justify-center text-center ">
                        <div className="mb-[40px]  ">
                            <div className="text-left  ">
                                <label htmlFor="otherRequests"
                                       className="text-[#6B7280] text-[14px] leading-[16px] text-left">기타 문의 / 요청 사항
                                </label>
                                <textarea type="text" id="otherRequests" placeholder={"-"} name="otherRequests"
                                          value={formData.otherRequests}
                                          onChange={handleChange}
                                          className="lg:w-[54rem] md:w-[440px] sm:w-[370px] h-[10rem] p-[10px] border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 block text-[14px] leading-[16px]"/>
                            </div>
                        </div>
                    </div>
                    <div className="relative flex justify-center mr-[30rem] md:w-full sm:w-full sm:-ml-2">
                        <input type="checkbox" id="agreePrivacy" className="mr-2"/>
                        <label htmlFor="agreePrivacy" className="text-xl sm:text-[1rem] text-black ">개인정보 수집 및 이용에
                            동의합니다.
                            <button
                                className={'modal-open-btn text-[#096AFB] border-b-2 border-[#096AFB] cursor-pointer ml-1'}
                                onClick={() => setModalOpen(true)}>
                                내용보기
                            </button>
                        </label>
                        {modalOpen &&
                            <div className="fixed inset-0 flex items-center justify-center z-50">
                                <div className="absolute inset-0 bg-black opacity-50 z-40"></div>
                                <div className="bg-white shadow-lg rounded-[16px] p-8 w-[600px] z-50">
                                    <h2 className="text-[24px] font-[700] mb-4">개인정보 활용동의</h2>
                                    <div className="bg-[#F3F4F6] p-4 rounded-md mb-2">
                                        <ul className="pl-4 font-[400] text-[14px]">
                                            <p className="mb-4 font-[500] text-[16px]">회사는 수집한 개인정보를 다음의 목적으로
                                                보유하고 활용합니다.</p>
                                            <li className={"mb-1"}><span className={"text-[10px]"}>①</span> 수집/이용 목적 :
                                                고객문의
                                                응대 및 상담
                                            </li>
                                            <li className={"mb-1"}><span className={"text-[10px]"}>②</span> 수집/이용 항목 :
                                                업체명,
                                                문의자, 이메일, 전화번호
                                            </li>
                                            <li className={"mb-1"}><span className={"text-[10px]"}>③</span> 보유 기간 : 문의처리
                                                완료일부터 1년
                                            </li>
                                        </ul>
                                    </div>
                                    <p className="text-gray-600">※ 본 동의는 당사의 고객문의를 이용하기 위해 필수로 필요한 항목입니다.</p>
                                    <div className={"flex justify-center mt-7"}>
                                        <button
                                            className="border-2 border-[#16196E]  w-5/12 hover:border-purple-600 font-bold py-2 px-4 rounded"
                                            onClick={() => setModalOpen(false)}>닫기
                                        </button>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                    <div className={"mx-auto mt-[66px] mb-24"}>
                        <button className="text-white bg-[#16196E] hover:bg-gray-900 w-[300px] h-[51px] rounded-[4px]"
                                onClick={qnaSubmit}>문의하기
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QnA3;
