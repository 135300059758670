import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slick/Custom-slick-theme.css';

import main0Img from '../../../images/home/web/main0.webp';
import main1Img from '../../../images/home/web/main1r.webp';
import main2Img from '../../../images/home/web/main2r.webp';
import main3Img from '../../../images/home/web/main3r.webp';
import main4Img from '../../../images/home/web/main4r.webp';
import main5Img from '../../../images/home/web/main5r.webp';
import left from '../../../images/home/web/left.svg';
import right from '../../../images/home/web/right.svg';

const Home1 = () => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [imageLoaded, setImageLoaded] = useState(Array(6).fill(false));

    const handleImageLoad = (index) => {
        const updatedImageLoaded = [...imageLoaded];
        updatedImageLoaded[index] = true;
        setImageLoaded(updatedImageLoaded);
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        let leftPosition = "79vw";
        if (window.innerWidth < 1024) {
            leftPosition = "calc(100vw - 300px)";
        } else if (1024 <= window.innerWidth && window.innerWidth < 99999) {
            leftPosition = "calc(100vw - 350px)";
        }

        return (
            <div className="sm:hidden" style={{ position: 'absolute', top: "calc(94vh - 160px)", left: leftPosition, zIndex: '4', alignItems: 'center' }}>
                <img src={left} className={className} onClick={onClick} alt="Left Arrow" style={{ ...style, scale: "200%" }} />
                <span style={{ color: 'white', marginTop: '10px', fontSize: '16px' }}>
                {currentSlideIndex + 1} / {slideData.length}
            </span>
            </div>
        );
    };

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;

        const rightStyle = {
            ...style,
            scale: "200%",
            top: "calc(94vh - 160px)",
            zIndex: 5,
        };
        if (window.innerWidth < 1024) {
            rightStyle.right = "110px";
        } else if (1024 <= window.innerWidth && window.innerWidth < 9999) {
            rightStyle.right = "150px";
        }

        return (
            <div className="sm:hidden">
                <img
                    src={right}
                    className={className}
                    style={rightStyle}
                    onClick={onClick}
                    alt="Right Arrow"
                />
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        cssEase: 'linear',
        pauseOnHover: false,
        pauseOnFocus: false,
        beforeChange: (current, next) => {
            setCurrentSlideIndex(next);
        },
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const slideData = [
        {
            img: main0Img,
            text1: (<div className="text-white">한강 작가 <br className="lg:block md:hidden sm:hidden" />2024 노벨문학상 수상을 축하드립니다.</div>),
        },
        {
            img: main1Img,
            text1: (<div className="text-white">도서 물류의 원활한 항해를 위한 파트너로서 <br className="lg:block md:hidden sm:hidden" />도서 물류 날개가 미래를 함께 열어갑니다.</div>),
        },
        {
            img: main2Img,
            text1: (<div className="text-white">전통, 전문성, 혁신이 융합되는 곳<br />날개가 34년 대한민국 도서 물류를 선도합니다.</div>),
        },
        {
            img: main3Img,
            text1: (<div className="text-white">국내 유일 최고의 프로페서널 <br className="sm:hidden" />반품 관리 시스템의 신화 창조</div>),
        },
        {
            img: main4Img,
            text1: (<div className="text-white">34년 정통의 도서 물류 노하우와 <br className="md:block sm:hidden" /> IT 스마트 재고관리 시스템의 성공적 만남</div>),
        },
        {
            img: main5Img,
            text1: (<div className="text-white">책과 함께 세상을 연결하는 가장 빠르고 안전한 길, <br/> 날개가 함께 합니다.</div>),
        },
    ];

    useEffect(() => {
        const existingStyles = document.querySelectorAll('.dynamic-style');
        existingStyles.forEach((styleElement) => {
            document.head.removeChild(styleElement);
        });

        const styles = `      
              @keyframes zoomOut {
                0% { transform: scale(1.2); }
                100% { transform: scale(1); }
            }

            .fade-image-${currentSlideIndex} {
                animation: zoomOut 5s ease-in-out forwards;
                object-fit: cover;
                width: 100%;
            }
            
            .slide-text1 {
                position: absolute;
                transform: translate(-100%, -50%);
                opacity: 0;
            }

            .slide-${currentSlideIndex} .slide-text1 {
                animation: textAnimation 1.2s ease forwards;
            }

            @keyframes textAnimation {
                0% {
                    opacity: 0;
                    transform: translateX(-100%);
                }
                100% {
                    opacity: 1;
                    transform: translateX(0%);
                }
            }

            .slick-slide[data-index="${currentSlideIndex}"] .fade-image-${currentSlideIndex} {
                opacity: 1;
            }  
        `;
        const styleElement = document.createElement('style');
        styleElement.classList.add('dynamic-style');
        styleElement.innerHTML = styles;
        document.head.appendChild(styleElement);

        return () => {
            document.head.removeChild(styleElement);
        };
    }, [currentSlideIndex]);

    return (
        <div className="overflow-x-hidden pt-[80px]
                        lg:min-h-[810px]
                        md:min-h-[640px]
                        sm:min-h-[480px]">
            <Slider {...settings}>
                {slideData.map((slide, index) => (
                    <div key={index} className={`slide-${index}`}>
                        <div className="relative w-[100vw] h-[calc(100vh-80px)]  ">
                            <img alt={`slide ${index + 1}`}
                                 className={`fade-image-${index} object-cover h-[calc(100vh-75px)] w-[100vw]`}
                                 src={slide.img}
                                 onLoad={() => handleImageLoad(index)}
                            />
                            {imageLoaded[index] && (
                                <div>
                                    <div className="absolute h-[100.79%] inset-0 bg-gradient-to-t from-[rgba(26,29,40,0.60)] via-[rgba(26,29,40,0)]"></div>
                                    <div className='absolute break-keep
                                                  1lg:mx-[120px] 1lg:bottom-[162px] 1lg:text-[46px] 1lg:leading-[60px] 1lg:font-[700]
                                                   lg:mx-[120px]  lg:bottom-[162px]  lg:text-[46px]  lg:leading-[60px]  lg:font-[700]
                                                   md:mx-[80px]   md:bottom-[162px]  md:text-[46px]  md:leading-[60px]  md:font-[700]
                                                   sm:mx-[40px]   sm:bottom-[53px]   sm:text-[32px]  sm:leading-[50px]  sm:font-[700]'>
                                        {slide.text1}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Home1;
