import React, { useEffect,useState } from "react";
import { gsap } from "gsap";

import vision1 from '../../../images/vision/vision1.png'
import vision2 from '../../../images/vision/vision2.png'
import vision3 from '../../../images/vision/vision3.png'
import vision4 from '../../../images/vision/vision4.png'
import vision5 from '../../../images/vision/vision5.jpg'

const Introduce2 = () => {

    const [Mobile, setMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth < 640);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    useEffect(() => {
        window.scrollTo(0, 0);

        // floatingObject 함수를 사용하여 이미지를 둥둥 떠다니게 만듭니다.
        function floatingObject(selector, delay, size) {
            gsap.to(selector, {
                duration: 1.5,
                y: size,
                repeat: -1,
                yoyo: true,
                ease: "power1.inOut",
                delay: delay
            });
        }

        function random(min, max) {
            return parseFloat((Math.random() * (max - min) + min).toFixed(2));
        }

        floatingObject('.floating2', random(0, 0.5), 15);
        floatingObject('.floating3', random(0.6, 1), 15);
    }, []);


    return (
        <div className={"overflow-auto"}>
            <div className={"mt-24"}>
            <span className="text-gray-800 font-bold
                             lg:text-[60px] lg:mx-[100px]
                             md:text-[48px] md:mx-[60px]
                             sm:text-[36px] sm:mx-[40px]   sm:my-5 ">핵심 가치</span>
            </div>
            <div className="flex justify-center my-5 mb-[200px]
                            2xl:ml-[26rem]
                            xl:ml-[13rem]
                            lg:ml-[13rem]
                            md:ml-[10rem]
                            ">
                <div className="flex flex-col min-w-[400px] ">
                    <div className="i1 floating2  sm:ml-[10rem]">
                        <img className="rounded-3xl min-w-[5vw] max-w-[80vw]
                                     lg:w-[400px] lg:h-[513px]
                                    md:w-[280px] md:h-[390px]
                                    sm:w-[200px] sm:h-[300px]
                                    "
                             src={vision1} alt="Core Value1"
                             onMouseEnter={() => {
                                 gsap.to('.i1 img', {scale: 1.03});
                             }}
                             onMouseLeave={() => {
                                 gsap.to('.i1 img', {scale: 1});
                             }}/>
                        <ul>
                            <li className={"mt-5 text-xl font-medium text-blue-400 sm:text-"}>SERVICE-EXCELLENCE</li>
                            <h3 className={"mt-8 text-4xl font-bold text-blue-800"}>호텔 서비스</h3>
                            <li className={"text-lg mt-5 font-semibold"}>고객 만족을 뛰어넘는 <br/> 5성 호텔의 서비스 제공을 목표합니다.</li>
                        </ul>
                    </div>
                    <div className="i2 floating2 my-40 sm:ml-[1rem] ">
                        <img className="rounded-3xl min-w-[5vw] max-w-[80vw]
                                     lg:w-[400px] lg:h-[513px]
                                    md:w-[280px] md:h-[390px]
                                    sm:w-[200px] sm:h-[300px]
                                    "
                             src={vision2} alt="Core Value2"
                             onMouseEnter={() => {
                                 gsap.to('.i2 img', {scale: 1.03});
                             }}
                             onMouseLeave={() => {
                                 gsap.to('.i2 img', {scale: 1});
                             }}/>
                        <ul>
                            <li className={"mt-5 text-xl font-medium text-blue-400"}>DELIGHT</li>
                            <h3 className={"mt-8 text-4xl font-bold text-blue-800"}>행복한 만남</h3>
                            <li className={"text-lg mt-5 font-semibold"}>고객과 함께 출판계의 밝은 미래를<br/>향해 걸어가는 것이 날개의 굳은 신념입니다.
                            </li>
                        </ul>
                    </div>
                    <div className="i5 floating2 sm:ml-[10rem] ">
                        <img className="rounded-3xl min-w-[5vw] max-w-[80vw] object-cover
                                     lg:w-[400px] lg:h-[513px]
                                    md:w-[280px] md:h-[390px]
                                    sm:w-[200px] sm:h-[300px]
                                    "
                             src={vision5} alt="vision5"
                             onMouseEnter={() => {
                                 gsap.to('.i5 img', {scale: 1.03});
                             }}
                             onMouseLeave={() => {
                                 gsap.to('.i5 img', {scale: 1});
                             }}/>
                        <ul>
                            <li className={"mt-5 text-xl font-medium text-blue-400"}>TOGETHER</li>
                            <h3 className={"mt-8 text-4xl font-bold text-blue-800"}>다 같이 함께</h3>
                            <li className={"text-lg mt-5 font-semibold break-keep"}>출판계가 플랫폼(첨단 원클릭 시스템)을 통해<br className="sm:hidden"/>
                                                                          출판사, 인쇄소, 서점, 물류 회사가 <br className="sm:hidden"/>
                                                                          다 같이 함께 하나가 되고 성장할 수 있는<br className="sm:hidden"/>
                                                                          최고의 출판 세계를 만들어갑니다.
                            </li>
                        </ul>
                    </div>

                    <div className="i3 floating3 mt-40 lg:hidden md:block sm:ml-[1rem]">
                        <img className="rounded-3xl min-w-[5vw] max-w-[80vw]
                                    lg:w-[400px] lg:h-[513px]
                                    md:w-[280px] md:h-[390px]
                                    sm:w-[200px] sm:h-[300px]
                                    "
                             src={vision3} alt="Core Value3"
                             onMouseEnter={() => {
                                 gsap.to('.i3 img', {scale: 1.03});
                             }}
                             onMouseLeave={() => {
                                 gsap.to('.i3 img', {scale: 1});
                             }}/>
                        <ul>
                            <li className={"mt-5 text-xl font-medium text-blue-400"}>INNOVATION</li>
                            <h3 className={"mt-8 text-4xl font-bold text-blue-800"}>오늘도 변화</h3>
                            <li className={"text-lg mt-5 font-semibold"}>날개는 최고의 서비스와 더 나은 시스템<br/> 개선을 위해 오늘도 변화합니다.
                            </li>
                        </ul>
                    </div>
                    <div className="i4 floating3 mt-60 sm:mt-24 mb-24 lg:hidden md:block sm:ml-[10rem]">
                        <img className="rounded-3xl min-w-[5vw] max-w-[80vw]
                                     lg:w-[400px] lg:h-[513px]
                                    md:w-[280px] md:h-[390px]
                                    sm:w-[200px] sm:h-[300px]
                                    "
                             src={vision4} alt="Core Value4"
                             onMouseEnter={() => {
                                 gsap.to('.i4 img', {scale: 1.03});
                             }}
                             onMouseLeave={() => {
                                 gsap.to('.i4 img', {scale: 1});
                             }}/>
                        <ul>
                            <li className={"mt-5 text-xl font-medium text-blue-400"}>DETAIL</li>
                            <h3 className={"mt-8 text-4xl font-bold text-blue-800"}>디테일</h3>
                            <li className={"text-lg mt-5 font-semibold"}>날개는 남다른 디테일을 위해<br/>항상 고민하고 행동합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="flex flex-col mt-[196px] ml-[12rem] min-w-[400px]
                                lg:block
                                md:hidden
                                sm:hidden
                                  ">
                    <div className="i3 floating3 mt-40 ">
                        <img className="rounded-3xl min-w-[5vw] max-w-[80vw]
                                     lg:w-[400px] lg:h-[513px]
                                    md:w-[280px] md:h-[390px]
                                    sm:w-[200px] sm:h-[300px]
                                    "
                             src={vision3} alt="Core Value3"
                             onMouseEnter={() => {
                                 gsap.to('.i3 img', {scale: 1.03});
                             }}
                             onMouseLeave={() => {
                                 gsap.to('.i3 img', {scale: 1});
                             }}/>
                        <ul>
                            <li className={"mt-5 text-xl font-medium text-blue-400"}>INNOVATION</li>
                            <h3 className={"mt-8 text-4xl font-bold text-blue-800"}>오늘도 변화</h3>
                            <li className={"text-lg mt-5 font-semibold"}>날개는 최고의 서비스와 더 나은 시스템<br/> 개선을 위해 오늘도 변화합니다.
                            </li>
                        </ul>
                    </div>
                    <div className="i4 floating3 mt-40 mb-24">
                        <img className="rounded-3xl min-w-[5vw] max-w-[80vw]
                                        lg:w-[400px] lg:h-[513px]
                                        md:w-[280px] md:h-[390px]
                                        sm:w-[200px] sm:h-[300px]
                                        "
                             src={vision4} alt="Core Value4"
                             onMouseEnter={() => {
                                 gsap.to('.i4 img', {scale: 1.03});
                             }}
                             onMouseLeave={() => {
                                 gsap.to('.i4 img', {scale: 1});
                             }}/>
                        <ul>
                            <li className={"mt-5 text-xl font-medium text-blue-400"}>DETAIL</li>
                            <h3 className={"mt-8 text-4xl font-bold text-blue-800"}>디테일</h3>
                            <li className={"text-lg mt-5 font-semibold"}>날개는 남다른 디테일을 위해<br/>항상 고민하고 행동합니다.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Introduce2;
